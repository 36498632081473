import {
  READ_LIST_VARIABLES,
  LOAD_LIST_VARIABLES,
  RESET_LIST_VARIABLES
} from "types/Types";

const initialState = {
  FOLDER_ROOT: "/opt",
  //FOLDER_ROOT: "/Users/sergiomolinagonzalez/Desktop",
  ENDPOINT: "https://api-gestrescan.com", 
  //ENDPOINT: "http://localhost:8000",
  DEFAULTSTORE: "1",
  DEFAULTEMPLOYEE: "1",
  DEFAULTTYPEOFDOCUMENT: "4",
  DEFAULTLANGUAGE: "1",
  DEFAULTCOLOR: "2",
  DEFAULTCOLOREMPLOYEE: "50",
  DEFAULTROLE: "4",
  LIMITPAGESIZE: "1000", 
  DEFAULTTYPESDI: "1",   
  NON_ERASABLE_CODELER_ID: "834",
  NON_ERASABLE_OPTTYPE_ID:"10",
  NON_ERASABLE_TREATMENTTYPE_ID: "85",
  NON_ERASABLE_PROCESSTYPE_ID: "29",
  NON_ERASABLE_DANGERROUS_ID: "15",
  NON_ERASABLE_COLOR_ID: "137",
  NON_ERASABLE_COMMUNITY_ID: "15",
  NON_ERASABLE_COUNTRY_ID: "247",
  NON_ERASABLE_DESCRIPTION_ID: "2",
  NON_ERASABLE_DOCUMENTTYPES_ID: "4",
  NON_ERASABLE_LANGUAGE_ID: "2",
  NON_ERASABLE_POSTALCODE_ID: "14617",
  NON_ERASABLE_PROVINCE_ID: "53",
  NON_ERASABLE_ROADTYPES_ID: "46",
  NON_ERASABLE_TYPESDI_ID: "4",
  NON_ERASABLE_ARTICLESBUTTON_ID: "33",
  NON_ERASABLE_TICKETSFORMAT_ID: "4",
  NON_ERASABLE_USERACCOUNT_ID: "8",
  NON_ERASABLE_STORE_ID: "3",
  NON_ERASABLE_EMPLOYEE_ID: "2",
  NON_ERASABLE_TYPESOFTAXES_ID:"10",
  NON_ERASABLE_ARTICLES_ID:"845",
  NON_ERASABLE_PAYMEMTTYPE_ID:"2"
};

export function variablesReducer(state = initialState, action) {
  switch (action.type) {
    case READ_LIST_VARIABLES:
      return state;
    case LOAD_LIST_VARIABLES: {
      return {
        ...state,
        FOLDER_ROOT: "/opt",
        //FOLDER_ROOT: "/Users/sergiomolinagonzalez/Desktop",
        ENDPOINT: "https://api-gestrescan.com",
        //ENDPOINT: "http://localhost:8000",
        DEFAULTSTORE:"1",
        DEFAULTEMPLOYEE: "1",
        DEFAULTTYPEOFDOCUMENT:"4",
        DEFAULTLANGUAGE: "1",
        DEFAULTCOLOR: "2",
        DEFAULTCOLOREMPLOYEE: "50",
        DEFAULTROLE: "4",
        LIMITPAGESIZE: "1000",
        DEFAULTTYPESDI: "1", 
        NON_ERASABLE_CODELER_ID: "834",
        NON_ERASABLE_OPTTYPE_ID:"10",
        NON_ERASABLE_TREATMENTTYPE_ID: "85",
        NON_ERASABLE_PROCESSTYPE_ID: "29",
        NON_ERASABLE_DANGERROUS_ID: "15",
        NON_ERASABLE_COLOR_ID: "137",
        NON_ERASABLE_COMMUNITY_ID: "15",
        NON_ERASABLE_COUNTRY_ID: "247",
        NON_ERASABLE_DESCRIPTION_ID: "2",
        NON_ERASABLE_DOCUMENTTYPES_ID: "4",
        NON_ERASABLE_LANGUAGE_ID: "2",
        NON_ERASABLE_POSTALCODE_ID: "14617",
        NON_ERASABLE_PROFESSION_ID: "1051",
        NON_ERASABLE_PROVINCE_ID: "53",
        NON_ERASABLE_ROADTYPES_ID: "46",
        NON_ERASABLE_TYPESDI_ID: "4",
        NON_ERASABLE_ARTICLESBUTTON_ID: "33",
        NON_ERASABLE_TICKETSFORMAT_ID: "4",
        NON_ERASABLE_USERACCOUNT_ID: "8",
        NON_ERASABLE_STORE_ID: "3",
        NON_ERASABLE_EMPLOYEE_ID:"2",
        NON_ERASABLE_TYPESOFTAXES_ID:"10",
        NON_ERASABLE_ARTICLES_ID:"845",
        NON_ERASABLE_PAYMEMTTYPE_ID:"2"
      };
    }
    case RESET_LIST_VARIABLES:
      return initialState;
    default:
      return state;
  }
}
