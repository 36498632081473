import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import ReportPurchseDelivery from 'pages/purchaseDelivery/generatePDF/ReportPurchaseDelivery'; 
import { helpHttp } from "service/HelpHttp";
import PropTypes from 'prop-types';

const initialStatePurchaseDelivery = {
  id : "",
  defaultStore_id:0, 
  tradename: "",
  useraccount_id:0,
  username: "",
  defaultformatnumber:1,
  isfinished:false, 
  purchasenote:0,
  numberticket:0, 
  ownnumberpurchase:"", 
  datepurchase: "",

  vehiclestore_id: 0,
  vehiclestore_licenseplate: "",
  vehiclestore_defaultdriver: "",
  vehiclestore_store_id: 0,
 
  rel_trans_store_id: 0,
  rel_trans_documnettype_id: 0,
  rel_trans_documentid: "",
  rel_trans_documnettype_name: "",
  rel_trans_tradename: "",
  rel_trans_name: "",
  rel_trans_contacperson: "",
  rel_trans_roadtype_id: 0,
  rel_trans_street: "",
  rel_trans_country_id: 1,
  rel_trans_province_id: 30,
  rel_trans_postalcode_id: 0,

  ori_tras_store_id: 0,
  ori_tras_documnettype_id: 0,
  ori_tras_documnettype_name: "",
  ori_tras_documentid: "",
  ori_tras_tradename: "",
  ori_tras_name: "",
  ori_tras_contacperson: "",
  ori_tras_roadtype_id: 0,
  ori_tras_street: "",
  ori_tras_country_id: 1,
  ori_tras_province_id: 30,
  ori_tras_postalcode_id: 0,

  observations: "",
  totalpurchasedelivery: 0,
  totaldiscountpurchasedelivery: 0,
  totaltaxespurchasedelivery:0,
  totalNetoPurchasedelivery:0,
  enabled:true,
  subownnumberpurchase:0,
  payment_id:0, 
  payment_code: "",
  payment_namees: "",
  payment_nameen: "", 
  
  totalWeigthNeto:0,
  signature_dispatched_by:"",
  signature_client:"",
  linespurchasedelivery:[]
};
 
const PurchaseDeliveryPDF = ({ rowEditPurchaseDelivery, viewPDF, setViewPDF }) => {
  const { t, i18n } = useTranslation(['listDataPurchases']);
  const token = useSelector((state) => state.loginUser.token);
  const defaulttypesdi = useSelector((state) => state.loginUser.defaulttypesdi.formatnumber);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT);
  const [dataPurchaseDelivery, setDataPurchaseDelivery] = useState(initialStatePurchaseDelivery);
  const dispatch = useDispatch();
  
  useEffect(() => {
    let isMounted = true;
    const fetchDataTickets = async () => {
     
      try {
        const getData = await helpHttp().get2(ENDPOINT, `purchasedelivery/getpurchasedeliverybyid/`, token, rowEditPurchaseDelivery.id);
        if (getData.err) {
          setDataPurchaseDelivery(initialStatePurchaseDelivery);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailed' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataPurchaseDelivery(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };
    if (rowEditPurchaseDelivery!==undefined && rowEditPurchaseDelivery.id!==undefined  && rowEditPurchaseDelivery.id!==null  && rowEditPurchaseDelivery.id!=="" && rowEditPurchaseDelivery.id!=="0" && rowEditPurchaseDelivery.id!==0
      && defaulttypesdi!==undefined  && defaulttypesdi!==null  && defaulttypesdi!=="" && defaulttypesdi!=="0" && defaulttypesdi!==0
    ) {
      fetchDataTickets();
    }
    
    return () => {isMounted = false;};
  }, [defaulttypesdi, rowEditPurchaseDelivery, ENDPOINT, dispatch, t, token,i18n.language]);  
 
  return (
    <div id="pdf-content" style={{ padding: 10 }}>
      {(viewPDF && dataPurchaseDelivery.id!==undefined  && dataPurchaseDelivery.id!==null  && dataPurchaseDelivery.id!=="" && dataPurchaseDelivery.id!=="0" && dataPurchaseDelivery.id!==0 
                && defaulttypesdi!==undefined  &&defaulttypesdi!==null  &&defaulttypesdi!=="" && defaulttypesdi!=="0" && defaulttypesdi!==0
      ) &&  <ReportPurchseDelivery dataPurchaseDelivery={dataPurchaseDelivery}  initialStatePurchaseDelivery={initialStatePurchaseDelivery} setDataPurchaseDelivery={setDataPurchaseDelivery} setViewPDF={setViewPDF}/> }
    </div>
  );
};


PurchaseDeliveryPDF.propTypes = {
  rowEditPurchaseDelivery: PropTypes.object.isRequired,
  viewPDF: PropTypes.bool.isRequired,
  setViewPDF: PropTypes.func.isRequired
};

export default PurchaseDeliveryPDF;
