import React from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { NumericFormat } from 'react-number-format';
import { Container, Box } from '@mui/material';   
import { TextField } from '@mui/material';
import { SwipeableDrawer } from '@mui/material';
import "css/generalSettings.css";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import IconButton from '@mui/material/IconButton' 
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import RegisterBookSearchDI from "pages/registerBook/editOrNewRegisterBook/RegisterBookSearchDI";
import RegisterBookSearchCodeLers from "pages/registerBook/editOrNewRegisterBook/RegisterBookSearchCodeLers";
import PropTypes from 'prop-types'; 

const RegisterBookEditOrCreate = ({ openCreateRegisterBook,openEditRowRegisterBook,errorsRegisterBook,setErrorsRegisterBook, formRegisterBook, setFormRegisterBook, setIsLoadingRegisterBook,valueTabs, handleCloseDataEditRegisterBook,showModalSearchDI, setShowModalSearchDI,showModalSearchCodeLERS,setShowModalSearchCodeLERS }) => {
    const {t,i18n} = useTranslation(['listDataRegisterBook']);
    const token = useSelector((state) => state.loginUser.token);  
    const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  
    const dispatch = useDispatch();

    const withValueCap = (inputObj) => {
        const { value } = inputObj;
        if (value <= 10000000000) return true;
        return false;
    };
    
    const handleBlurDataEditAmount = (e) => {
        handleChangeDataEditAmount(e);
        setErrorsRegisterBook(validateForm());
    };
        
    const handleChangeDataEditAmount = (e) => {
        const { name, value } = e.target;
        let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll(" kg","")):0;
        setFormRegisterBook(formRegisterBook => ({
          ...formRegisterBook,
          [name]: newValue,
        }));
    };

    const handleBlurDataEditRegisterBook = (e) => {
        handleChangeDataEditRegisterBook(e);
        setErrorsRegisterBook(validateForm());
    };
    
    const handleChangeDataEditRegisterBook = (e) => {
        const { name, value } = e.target;
        setFormRegisterBook(formRegisterBook => ({
          ...formRegisterBook,
          [name]: value
        }));
    };
    
    const handleBlurSelectDateSheet = (e) => {
        handleChangeSelectDateSheet(e);
        setErrorsRegisterBook(validateForm());
    };
    
    const handleChangeSelectDateSheet = (event) => {
        setFormRegisterBook(formRegisterBook => ({
          ...formRegisterBook,
          date_sheet: event!==null?new Date(event):null
        }));
    };

    const toggleDrawerDataEdit = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        if (event.type === 'keydown') {
          event.preventDefault()
          handleCloseDataEditRegisterBook();
        }
    };

    const deleteDataCodeLER = (event) => {
      setFormRegisterBook(formRegisterBook => ({
        ...formRegisterBook, 
        codeler:"",
        namecodeler:"" 
      }));  
      setErrorsRegisterBook(validateForm());
    };

    const searchCodeLER = (event) => { 
      setShowModalSearchCodeLERS(true);
    };

    const deleteDataAll = (event) => {
      setFormRegisterBook(formRegisterBook => ({
        ...formRegisterBook,
        formdi_id:0,
        formdi_ownformdi:0,
        preiddoc_sheet:"",
        sufiddoc_sheet:0,
        number_dt:"",
        date_sheet:null,
        ori_tras_tradename:"",
        ori_tras_nima:"",
        ori_tras_postalcode:"",
        opt_tras_tradename:"",
        opt_tras_nima:"",
        amount:0,
        codeler:"",
        namecodeler:"",
        rel_trans_tradename:"",
        rel_trans_nima:"",
        dest_tras_tradename:"",
        dest_tras_nima:"",
        dest_tras_inscription:"",
        dest_tras_postalcode:"",
        codetreatment:"",
        nametreatment:"",
        codedangerousness:"",
        namedangerousness:"" 
      }));  
      setErrorsRegisterBook(validateForm());
    };

    const searchFormDI = (event) => { 
      setShowModalSearchDI(true);
    };

    const validateForm = () => {
        let errorsRegisterBook = {};
        let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
     
        if (regexInvalidCharacters.test(formRegisterBook.formdi_id)) {
          errorsRegisterBook.formdi_id = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.preiddoc_sheet)) {
          errorsRegisterBook.preiddoc_sheet = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.number_dt)) {
          errorsRegisterBook.number_dt = 'Text_TextNoValid';
        }  
        if (regexInvalidCharacters.test(formRegisterBook.ori_tras_tradename)) {
          errorsRegisterBook.ori_tras_tradename = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.ori_tras_nima)) {
          errorsRegisterBook.ori_tras_nima = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.ori_tras_postalcode)) {
          errorsRegisterBook.ori_tras_postalcode = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.opt_tras_tradename)) {
          errorsRegisterBook.opt_tras_tradename = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.opt_tras_nima)) {
          errorsRegisterBook.opt_tras_nima = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.codeler)) {
          errorsRegisterBook.codeler = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.namecodeler)) {
          errorsRegisterBook.namecodeler = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.rel_trans_tradename)) {
          errorsRegisterBook.rel_trans_tradename = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.rel_trans_nima)) {
          errorsRegisterBook.rel_trans_nima = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.dest_tras_tradename)) {
          errorsRegisterBook.dest_tras_tradename = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.dest_tras_nima)) {
          errorsRegisterBook.dest_tras_nima = 'Text_TextNoValid';
        }
        if (regexInvalidCharacters.test(formRegisterBook.dest_tras_inscription)) {
          errorsRegisterBook.dest_tras_inscription = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.dest_tras_postalcode)) {
          errorsRegisterBook.dest_tras_postalcode = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.codetreatment)) {
          errorsRegisterBook.codetreatment = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.nametreatment)) {
          errorsRegisterBook.nametreatment = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.codedangerousness)) {
          errorsRegisterBook.codedangerousness = 'Text_TextNoValid';
        } 
        if (regexInvalidCharacters.test(formRegisterBook.namedangerousness)) {
          errorsRegisterBook.namedangerousness = 'Text_TextNoValid';
        }
        if (regexInvalidCharacters.test(formRegisterBook.column1)) {
          errorsRegisterBook.column1 = 'Text_TextNoValid';
        }
        if (regexInvalidCharacters.test(formRegisterBook.column2)) {
          errorsRegisterBook.column2 = 'Text_TextNoValid';
        }   
        return errorsRegisterBook;
    }; 
      
    const handleSubmitDataEditRegisterBook = () => {
        setErrorsRegisterBook(validateForm());
        const errorsToCheck = validateForm();
      
        if (Object.keys(errorsToCheck).length === 0) {
          const fetchData = async () => {
            setIsLoadingRegisterBook(true);
            try {
              const getData= (formRegisterBook.id !== undefined && formRegisterBook.id !== null && formRegisterBook.id !== "" && formRegisterBook.id !== "0" && formRegisterBook.id !== 0)
                ? await helpHttp().put(ENDPOINT, `register/updateregisterbook`, token, formRegisterBook)
                : await helpHttp().post(ENDPOINT, `register/saveregisterbook`, token, formRegisterBook);
      
              if (getData.status === "OK") {
                dispatch(successErrorAction(t('Text_SaveData')));
               
              } else {
                dispatch(warningErrorAction(t(getData.cause)));
              }
            } catch (error) {
              dispatch(warningErrorAction(t('Text_IncorrectData')));
              setIsLoadingRegisterBook(false);
            } 
          };
        
          fetchData();
          setIsLoadingRegisterBook(false);
          handleCloseDataEditRegisterBook();
        } else {
          if (errorsToCheck.formdi_id) {
            dispatch(warningErrorAction(t(errorsToCheck.formdi_id)));
          }  else {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
          }
        }
    };

    return (
        <> 
        {(openCreateRegisterBook || openEditRowRegisterBook)&& 
            <div>
                <SwipeableDrawer
                    anchor='right'
                    open={true}
                    onClose={toggleDrawerDataEdit(false)}
                    onOpen={toggleDrawerDataEdit(true)}>
                    <Container >
                    <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                        <br/>
                        <div id="myDIV">
                        <h1 className="h1NewStyle px-4 mb-2">{openEditRowRegisterBook?(valueTabs==='sheetin'?t('Text_EditSheetIn'):t('Text_EditSheetOut')):(valueTabs==='sheetin'?t('Text_CreateSheetIn'):t('Text_CreateSheetOut'))}</h1>
                        </div>  
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <form className="createcodeler" onSubmit={handleSubmitDataEditRegisterBook}>  
                        <div className="form-floating mb-3 px-4 primary">
                            <label className="text-primary">{t('Text_Id')} :</label>
                            <div className="form-group">
                              <TextField
                                id="registerbook_id" 
                                name="registerbook_id"
                                value={formRegisterBook.registerbook_id}
                                inputProps={{ style: {width: 350}, maxLength: 10 }}
                                label={t('PlaceHolder_Code_Register')}
                                placeholder={t('PlaceHolder_Code_Register')}
                                variant="standard"
                                disabled/>
                            </div> 
                            
                            <label className="text-primary">{t('Text_NUMBER_DI')} :</label>
                            <div className="form-group">
                              <TextField
                                id="formdi_id" 
                                name="formdi_id"
                                value={formRegisterBook.formdi_id}
                                inputProps={{ style: {width: 350}, maxLength: 10 }}
                                label={errorsRegisterBook.formdi_id!=null?t(errorsRegisterBook.formdi_id):t('PlaceHolder_NUMBER_DI')}
                                placeholder={t('PlaceHolder_NUMBER_DI')}
                                variant="standard"
                                onBlur={handleBlurDataEditRegisterBook}
                                onChange={handleChangeDataEditRegisterBook}
                                disabled/>
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataAll}>
                                    <DeleteIcon/>
                                </IconButton>
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchFormDI}>
                                  <SearchIcon/>
                                </IconButton> 
                            </div>

                            <label className="text-primary">{t('Text_Prefix')} :</label>
                            <div className="form-group">
                              <TextField
                                id="preiddoc_sheet" 
                                name="preiddoc_sheet"
                                value={formRegisterBook.preiddoc_sheet}
                                inputProps={{ style: {width: 350}, maxLength: 20 }}
                                label={errorsRegisterBook.preiddoc_sheet!=null?t(errorsRegisterBook.preiddoc_sheet):t('PlaceHolder_Prefix')}
                                placeholder={t('PlaceHolder_Prefix')}
                                variant="standard"
                                onBlur={handleBlurDataEditRegisterBook}
                                onChange={handleChangeDataEditRegisterBook}/>
                            </div>

                            <label className="text-primary">{t('Text_Sufix')} :</label>
                            <div className="form-group">
                              <NumericFormat 
                                thousandSeparator={''} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="sufiddoc_sheet" 
                                name="sufiddoc_sheet"
                                inputProps={{ style: {width: 320}, maxLength: 20 }}
                                value={formRegisterBook.sufiddoc_sheet}
                                label={errorsRegisterBook.sufiddoc_sheet!=null?t(errorsRegisterBook.sufiddoc_sheet):t('PlaceHolder_Sufix')}
                                placeholder={t('PlaceHolder_Sufix')}
                                customInput={TextField}
                                onBlur={handleBlurDataEditAmount}
                                onChange={handleChangeDataEditAmount}
                              />  
                            </div>
                            <label className={"text-primary"}>{t('Text_Date')} :</label>
                            <div className="form-group">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DesktopDatePicker
                                    id="date_sheet" 
                                    name="date_sheet"
                                    value={ formRegisterBook.date_sheet!== null ? new Date(formRegisterBook.date_sheet): null}
                                    label={errorsRegisterBook.date_sheet!=null?t(errorsRegisterBook.date_sheet):t('PlaceHolder_Date')}
                                    format={i18n.language==="es"?"dd/MM/yyyy":"mm/DD/yyyy"}
                                    inputProps={{ style: {width: 320}, maxLength: 20 }}
                                    onBlur={handleBlurSelectDateSheet}
                                    onChange={handleChangeSelectDateSheet}
                                    slotProps={{
                                    textField: {
                                        placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                        sx: {
                                        color: '#1565c0',
                                        maxLength: '100'
                                        }
                                    },
                                    }}
                                />
                                </LocalizationProvider>
                            </div>  
                            <label className="text-primary">{t('Text_NumberNT')} :</label>
                            <div className="form-group">
                            <TextField
                                id="number_dt" 
                                name="number_dt"
                                value={formRegisterBook.number_dt}
                                inputProps={{ style: {width: 350}, maxLength: 20 }}
                                label={errorsRegisterBook.number_dt!=null?t(errorsRegisterBook.number_dt):t('PlaceHolder_NumberNT')}
                                placeholder={t('PlaceHolder_NumberNT')}
                                variant="standard"
                                onBlur={handleBlurDataEditRegisterBook}
                                onChange={handleChangeDataEditRegisterBook}/>
                            </div>
                            {valueTabs==='sheetin' ?
                                <>
                                    <label className="text-primary">{t('Text_Ori_Tras_Tradename')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="ori_tras_tradename" 
                                            name="ori_tras_tradename"
                                            value={formRegisterBook.ori_tras_tradename}
                                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                                            label={errorsRegisterBook.ori_tras_tradename!=null?t(errorsRegisterBook.ori_tras_tradename):t('PlaceHolder_Ori_Tras_Tradename')}
                                            placeholder={t('PlaceHolder_Ori_Tras_Tradename')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>
                                    <label className="text-primary">{t('Text_Ori_Tras_Nima')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="ori_tras_nima" 
                                            name="ori_tras_nima"
                                            value={formRegisterBook.ori_tras_nima}
                                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                                            label={errorsRegisterBook.ori_tras_nima!=null?t(errorsRegisterBook.ori_tras_nima):t('PlaceHolder_Ori_Tras_Nima')}
                                            placeholder={t('PlaceHolder_Ori_Tras_Nima')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>
                                    <label className="text-primary">{t('Text_Ori_Tras_PostalCode')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="ori_tras_postalcode" 
                                            name="ori_tras_postalcode"
                                            value={formRegisterBook.ori_tras_postalcode}
                                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                                            label={errorsRegisterBook.ori_tras_postalcode!=null?t(errorsRegisterBook.ori_tras_postalcode):t('PlaceHolder_Ori_Tras_PostalCode')}
                                            placeholder={t('PlaceHolder_Ori_Tras_PostalCode')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>

                                    <label className="text-primary">{t('Text_Opt_Tras_Tradename')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="opt_tras_tradename" 
                                            name="opt_tras_tradename"
                                            value={formRegisterBook.opt_tras_tradename}
                                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                                            label={errorsRegisterBook.opt_tras_tradename!=null?t(errorsRegisterBook.opt_tras_tradename):t('PlaceHolder_Opt_Tras_Tradename')}
                                            placeholder={t('PlaceHolder_Opt_Tras_Tradename')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>
                                    <label className="text-primary">{t('Text_Opt_Tras_Nima')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="opt_tras_nima" 
                                            name="opt_tras_nima"
                                            value={formRegisterBook.opt_tras_nima}
                                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                                            label={errorsRegisterBook.opt_tras_nima!=null?t(errorsRegisterBook.opt_tras_nima):t('PlaceHolder_Opt_Tras_Nima')}
                                            placeholder={t('PlaceHolder_Opt_Tras_Nima')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div> 
                                    <label className="text-primary">{t('Text_Amount')} :</label>
                                    <div className="form-group">
                                        <NumericFormat 
                                            thousandSeparator={','} 
                                            decimalSeparator={'.'} 
                                            decimalScale= {2}
                                            fixedDecimalScale= {true}
                                            allowNegative= {false}
                                            isAllowed={withValueCap}
                                            id="amount" 
                                            name="amount" 
                                            value={formRegisterBook.amount}
                                            label={errorsRegisterBook.amount!=null?t(errorsRegisterBook.amount):t('PlaceHolder_Amount')}
                                            placeholder={t('PlaceHolder_Amount')}
                                            style={{width: 320}}
                                            customInput={TextField}
                                            onBlur={handleBlurDataEditAmount}
                                            onChange={handleChangeDataEditAmount}
                                        />
                                    </div>
                                    <label className="text-primary">{t('Text_CodeLer')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="codeler" 
                                            name="codeler"
                                            value={formRegisterBook.codeler}
                                            inputProps={{ style: {width: 350}, maxLength: 10 }}
                                            label={errorsRegisterBook.codeler!=null?t(errorsRegisterBook.codeler):t('PlaceHolder_CodeLer')}
                                            placeholder={t('PlaceHolder_CodeLer')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}
                                            disabled/>
                                            &nbsp;&nbsp;
                                            <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataCodeLER}>
                                                <DeleteIcon/>
                                            </IconButton>
                                            &nbsp;&nbsp;
                                            <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchCodeLER}>
                                              <SearchIcon/>
                                            </IconButton> 
                                    </div>
                                    <label className="text-primary">{t('Text_DescriptionCodeLer')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="namecodeler" 
                                            name="namecodeler"
                                            value={formRegisterBook.namecodeler}
                                            inputProps={{ style: {width: 450}, maxLength: 256 }}
                                            label={errorsRegisterBook.namecodeler!=null?t(errorsRegisterBook.namecodeler):t('PlaceHolder_DescriptionCodeLer')}
                                            placeholder={t('PlaceHolder_DescriptionCodeLer')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}
                                            disabled/>
                                    </div>
                                    <label className="text-primary">{t('Text_Rel_Trans_Tradename')} :</label>
                                    <div className="form-group">
                                      <TextField
                                            id="rel_trans_tradename" 
                                            name="rel_trans_tradename"
                                            value={formRegisterBook.rel_trans_tradename}
                                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                                            label={errorsRegisterBook.rel_trans_tradename!=null?t(errorsRegisterBook.rel_trans_tradename):t('PlaceHolder_Rel_Trans_Tradename')}
                                            placeholder={t('PlaceHolder_Rel_Trans_Tradename')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>
                                    <label className="text-primary">{t('Text_Rel_Trans_Nima')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="rel_trans_nima" 
                                            name="rel_trans_nima"
                                            value={formRegisterBook.rel_trans_nima}
                                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                                            label={errorsRegisterBook.rel_trans_nima!=null?t(errorsRegisterBook.rel_trans_nima):t('PlaceHolder_Rel_Trans_Nima')}
                                            placeholder={t('PlaceHolder_Rel_Trans_Nima')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>
                                </>:
                                <>
                                    <label className="text-primary">{t('Text_CodeLer')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="codeler" 
                                            name="codeler"
                                            value={formRegisterBook.codeler}
                                            inputProps={{ style: {width: 350}, maxLength: 10 }}
                                            label={errorsRegisterBook.codeler!=null?t(errorsRegisterBook.codeler):t('PlaceHolder_CodeLer')}
                                            placeholder={t('PlaceHolder_CodeLer')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}
                                            disabled/>
                                            &nbsp;&nbsp;
                                            <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataCodeLER}>
                                                <DeleteIcon/>
                                            </IconButton>
                                            &nbsp;&nbsp;
                                            <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchCodeLER}>
                                              <SearchIcon/>
                                            </IconButton> 
                                    </div>
                                    <label className="text-primary">{t('Text_DescriptionCodeLer')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="namecodeler" 
                                            name="namecodeler"
                                            value={formRegisterBook.namecodeler}
                                            inputProps={{ style: {width: 450}, maxLength: 256 }}
                                            label={errorsRegisterBook.namecodeler!=null?t(errorsRegisterBook.namecodeler):t('PlaceHolder_DescriptionCodeLer')}
                                            placeholder={t('PlaceHolder_DescriptionCodeLer')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}
                                            disabled/>
                                    </div>
                                    <label className="text-primary">{t('Text_Dest_Tras_Tradename')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="dest_tras_tradename" 
                                            name="dest_tras_tradename"
                                            value={formRegisterBook.dest_tras_tradename}
                                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                                            label={errorsRegisterBook.dest_tras_tradename!=null?t(errorsRegisterBook.dest_tras_tradename):t('PlaceHolder_Dest_Tras_Tradename')}
                                            placeholder={t('PlaceHolder_Dest_Tras_Tradename')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>
                                    <label className="text-primary">{t('Text_Dest_Tras_Nima')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="dest_tras_nima" 
                                            name="dest_tras_nima"
                                            value={formRegisterBook.dest_tras_nima}
                                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                                            label={errorsRegisterBook.dest_tras_nima!=null?t(errorsRegisterBook.dest_tras_nima):t('PlaceHolder_Dest_Tras_Nima')}
                                            placeholder={t('PlaceHolder_Dest_Tras_Nima')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>
                                    <label className="text-primary">{t('Text_Dest_Tras_PyGR')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="dest_tras_inscription" 
                                            name="dest_tras_inscription"
                                            value={formRegisterBook.dest_tras_inscription}
                                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                                            label={errorsRegisterBook.dest_tras_inscription!=null?t(errorsRegisterBook.dest_tras_inscription):t('PlaceHolder_Dest_Tras_PyGR')}
                                            placeholder={t('PlaceHolder_Dest_Tras_PyGR')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>
                                    <label className="text-primary">{t('Text_Dest_Tras_PostalCode')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="dest_tras_postalcode" 
                                            name="dest_tras_postalcode"
                                            value={formRegisterBook.dest_tras_postalcode}
                                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                                            label={errorsRegisterBook.dest_tras_postalcode!=null?t(errorsRegisterBook.dest_tras_postalcode):t('PlaceHolder_Dest_Tras_PostalCode')}
                                            placeholder={t('PlaceHolder_Dest_Tras_PostalCode')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>
                                    <label className="text-primary">{t('Text_Rel_Trans_Tradename')} :</label>
                                    <div className="form-group">
                                      <TextField
                                            id="rel_trans_tradename" 
                                            name="rel_trans_tradename"
                                            value={formRegisterBook.rel_trans_tradename}
                                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                                            label={errorsRegisterBook.rel_trans_tradename!=null?t(errorsRegisterBook.rel_trans_tradename):t('PlaceHolder_Rel_Trans_Tradename')}
                                            placeholder={t('PlaceHolder_Rel_Trans_Tradename')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>
                                    <label className="text-primary">{t('Text_Rel_Trans_Nima')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="rel_trans_nima" 
                                            name="rel_trans_nima"
                                            value={formRegisterBook.rel_trans_nima}
                                            inputProps={{ style: {width: 450}, maxLength: 100 }}
                                            label={errorsRegisterBook.rel_trans_nima!=null?t(errorsRegisterBook.rel_trans_nima):t('PlaceHolder_Rel_Trans_Nima')}
                                            placeholder={t('PlaceHolder_Rel_Trans_Nima')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>
                                    <label className="text-primary">{t('Text_CodeTreatment')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="codetreatment" 
                                            name="codetreatment"
                                            value={formRegisterBook.codetreatment}
                                            inputProps={{ style: {width: 450}, maxLength: 10 }}
                                            label={errorsRegisterBook.codetreatment!=null?t(errorsRegisterBook.codetreatment):t('PlaceHolder_CodeTreatment')}
                                            placeholder={t('PlaceHolder_CodeTreatment')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>
                                    <label className="text-primary">{t('Text_DescriptionTreatment')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="nametreatment" 
                                            name="nametreatment"
                                            value={formRegisterBook.nametreatment}
                                            inputProps={{ style: {width: 450}, maxLength: 256 }}
                                            label={errorsRegisterBook.nametreatment!=null?t(errorsRegisterBook.nametreatment):t('PlaceHolder_DescriptionTreatment')}
                                            placeholder={t('PlaceHolder_DescriptionTreatment')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>
                                    <label className="text-primary">{t('Text_CodeDangerousness')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="codedangerousness" 
                                            name="codedangerousness"
                                            value={formRegisterBook.codedangerousness}
                                            inputProps={{ style: {width: 450}, maxLength: 10 }}
                                            label={errorsRegisterBook.codedangerousness!=null?t(errorsRegisterBook.codedangerousness):t('PlaceHolder_CodeDangerousness')}
                                            placeholder={t('PlaceHolder_CodeDangerousness')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>
                                    <label className="text-primary">{t('Text_DescriptionCodeDangerousness')} :</label>
                                    <div className="form-group">
                                        <TextField
                                            id="namedangerousness" 
                                            name="namedangerousness"
                                            value={formRegisterBook.namedangerousness}
                                            inputProps={{ style: {width: 450}, maxLength: 256 }}
                                            label={errorsRegisterBook.namedangerousness!=null?t(errorsRegisterBook.namedangerousness):t('PlaceHolder_DescriptionCodeDangerousness')}
                                            placeholder={t('PlaceHolder_DescriptionCodeDangerousness')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditRegisterBook}
                                            onChange={handleChangeDataEditRegisterBook}/>
                                    </div>
                                    
                                </>
                            }   
                            <label className="text-primary">{t('Columna')} :</label>
                            <div className="form-group">
                            <TextField
                                id="column1" 
                                name="column1"
                                value={formRegisterBook.column1}
                                inputProps={{ style: {width: 450}, maxLength: 256 }}
                                label={errorsRegisterBook.column1!=null?t(errorsRegisterBook.column1):t('PlaceHolder_Column1')}
                                placeholder={t('PlaceHolder_Column1')}
                                variant="standard"
                                onBlur={handleBlurDataEditRegisterBook}
                                onChange={handleChangeDataEditRegisterBook}/>
                            </div>
                            <label className="text-primary">{t('Text_Column2')} :</label>
                            <div className="form-group">
                            <TextField
                                id="column2" 
                                name="column2"
                                value={formRegisterBook.column2}
                                inputProps={{ style: {width: 450}, maxLength: 256 }}
                                label={errorsRegisterBook.column2!=null?t(errorsRegisterBook.column2):t('PlaceHolder_Column2')}
                                placeholder={t('PlaceHolder_Column2')}
                                variant="standard"
                                onBlur={handleBlurDataEditRegisterBook}
                                onChange={handleChangeDataEditRegisterBook}/>
                            </div> 
                            <hr className="sidebar-divider d-none d-md-block"/>
                            <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                            <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitDataEditRegisterBook}>{openEditRowRegisterBook?t('Button_Update'):t('Button_Create')}</button><> </>
                            <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseDataEditRegisterBook}>{t('Button_Cancel')}</button>
                            </div>
                        </div>
                        </form>
                    </Box>
                    </Container>
                </SwipeableDrawer> 
                <RegisterBookSearchDI
                  showModalSearchDI={showModalSearchDI}
                  setShowModalSearchDI={setShowModalSearchDI}
                  formRegisterBook={formRegisterBook}
                  setFormRegisterBook={setFormRegisterBook} 
                />
                <RegisterBookSearchCodeLers
                  showModalSearchCodeLERS={showModalSearchCodeLERS}
                  setShowModalSearchCodeLERS={setShowModalSearchCodeLERS} 
                  setFormRegisterBook={setFormRegisterBook}
                />
            </div>
            
            
            }
        </>
    )
  }
  
  RegisterBookEditOrCreate.propTypes = { 
    openCreateRegisterBook: PropTypes.bool.isRequired,
    openEditRowRegisterBook: PropTypes.bool.isRequired, 
    setErrorsRegisterBook: PropTypes.func.isRequired, 
    setFormRegisterBook: PropTypes.func.isRequired,
    setIsLoadingRegisterBook: PropTypes.func.isRequired,
    valueTabs: PropTypes.string.isRequired,
    handleCloseDataEditRegisterBook: PropTypes.func.isRequired,
    showModalSearchDI: PropTypes.bool.isRequired,
    setShowModalSearchDI: PropTypes.func.isRequired,
    showModalSearchCodeLERS: PropTypes.bool.isRequired,
    setShowModalSearchCodeLERS: PropTypes.func.isRequired
  };
  
  export default RegisterBookEditOrCreate;