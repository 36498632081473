import React, {useState, useEffect} from 'react' 
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next"
import { TextField } from '@mui/material';
import { Checkbox} from  '@mui/material';
import { NumericFormat } from 'react-number-format';
import { Toolbar,TableFooter,TablePagination,TableSortLabel,Table,TableBody,TableCell,TableContainer, TableHead,TableRow,Paper,Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit'; 
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';

const headCellsPurchaseDelivery = [
  { id: 'ownnumberpurchase', numeric: false, disablePadding: false, label: 'Text_PURCHASEDELIVERY_OWNNUMPURCHASE_UPPER', canOrder: true },
  { id: 'purchasenote', numeric: false, disablePadding: false, label: 'Text_NumberPurchase', canOrder: true },
  { id: 'numberticket', numeric: false, disablePadding: false, label: 'Text_PURCHASEDELIVERY_TICKET', canOrder: true },
  { id: 'datepurchase', numeric: false, disablePadding: false, label: 'Text_PurchaseDeliver_Date', canOrder: true },
  { id: 'rel_trans_tradename', numeric: false, disablePadding: false, label: 'Text_PurchaseDeliver_TRADENAME_TRANS', canOrder: true },
  { id: 'vehiclestore_licenseplate', numeric: false, disablePadding: false, label: 'Text_PurchaseDeliver_LICENSEPLATE', canOrder: true },
  { id: 'ori_tras_tradename', numeric: false, disablePadding: false, label: 'Text_PurchaseDeliver_STORE', canOrder: true },
  { id: 'totalpurchase', numeric: false, disablePadding: false, label: 'Text_PurchaseDeliver_TOTAL', canOrder: true } ,
  { id: 'payment_name', numeric: false, disablePadding: false, label: 'Text_PurchaseDeliver_Payment', canOrder: true },
  { id: 'formDIAssociates', numeric: false, disablePadding: false, label: 'Text_PurchaseDeliver_ASSOCIATES_DI', canOrder: true }
];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
  
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
          return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t,showFinishButtonPurchaseDelivery, showEditButtonPurchaseDelivery,showPrintButtonPurchaseDelivery,showDeleteButtonPurchaseDelivery,showStorePurchaseDelivery,showUserAccountPurchaseDelivery,selectAll,selectAllPendig,handleChangSelectAll,handleChangSelectAllPending,typeOfList} = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {headCellsPurchaseDelivery.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))}
         {showFinishButtonPurchaseDelivery &&
          <TableCell
            key='pending'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#4e73df", fontSize: 14}} >
              {(typeOfList==="1" || typeOfList==="3")?t('Text_Finish'):t('Text_Return')}
            </Typography>
            <Checkbox checked={(typeOfList==="1" || typeOfList==="3")?selectAll:selectAllPendig} style={{color:"#4e73df", fontSize: 14}} onClick={(typeOfList==="1" || typeOfList==="3")?()=>handleChangSelectAll():()=>handleChangSelectAllPending()}/>
          </TableCell>
        }
        {showEditButtonPurchaseDelivery &&
          <TableCell
            key='edit'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#4e73df", fontSize: 14}} >
              {t('Button_Edit')}
            </Typography>
          </TableCell>
        }
        {showPrintButtonPurchaseDelivery &&
          <TableCell
            key='print'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#e74a3b", fontSize: 14}} >
              {t('Button_Print_PDF')}
            </Typography>
          </TableCell>
        }

        {showDeleteButtonPurchaseDelivery &&
          <TableCell
            key='delete'
            align='center'
            padding='normal'
            sortDirection={false}
          >
            <Typography style={{color:"#e74a3b", fontSize: 14}} > 
              {t('Button_Delete')}
            </Typography>
          </TableCell>
        }
        {showStorePurchaseDelivery &&
          <TableCell
            key='tradename'
            align='center'
            padding='normal'
            sortDirection={order}
          >
            <TableSortLabel
              active={orderBy === 'tradename'}
              direction={orderBy === 'tradename' ? order : 'asc'}
              onClick={createSortHandler('tradename')}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t('Text_Store_Create')}
                {orderBy === 'tradename' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>
          </TableCell>
        }
        {showUserAccountPurchaseDelivery &&
          <TableCell
            key='username'
            align='center'
            padding='normal'
            sortDirection={order}
          >
            <TableSortLabel
              active={orderBy === 'username'}
              direction={orderBy === 'username' ? order : 'asc'}
              onClick={createSortHandler('username')}
            >
              <Typography style={{color:"#4e73df", fontSize: 14}} >  
                {t('Text_UserAcount_Create')}
                {orderBy === 'username' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>
          </TableCell>
        }  
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { textHead, textReg, numTotRegFilter,numTotRegPurchaseDelivery, textOf  } = props;
    return (
      <Toolbar>
        <Typography style={{flex: '1 1 100%',color:"#4e73df", fontSize: 28}} id="tableTitle" component="div">
          {textHead}
        </Typography>
        <Typography style={{flexShrink: 0, ml: 2.5, color:"#4e73df", fontSize: 18}} id="tableTitle" component="div">
          {textReg} {(numTotRegFilter).toLocaleString('de-DE')} {textOf} {(numTotRegPurchaseDelivery).toLocaleString('de-DE')}
        </Typography>
    </Toolbar>
    );
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataPurchases']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TableSortingPurchaseDelivery =(props)=>{
  const { 
    dataPurchaseDelivery, 
    setFormPurchaseDelivery,
    numTotRegFilter,
    titleTable,
    setOpenDeleteRowPurchaseDelivery,
    setRowEditPurchaseDelivery, 
    showFinishButtonPurchaseDelivery,
    showEditButtonPurchaseDelivery,
    showPrintButtonPurchaseDelivery,
    showDeleteButtonPurchaseDelivery,
    showStorePurchaseDelivery,
    showUserAccountPurchaseDelivery,
    numTotRegPurchaseDelivery,
    activeOffsetPurchaseDelivery,
    rangeOffsetPurchaseDelivery,
    currencyOffsetPurchaseDelivery,
    setCurrencyOffsetPurchaseDelivery,
    setViewPDF,
    typeOfList
  } = props;
  const {t,i18n} = useTranslation(['listDataPurchases']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataPurchaseDelivery.length) : 0);
  const [countData,setCountData] = useState(dataPurchaseDelivery.length);
  const [selectAll,setSelectAll]= useState(false);
  const [selectAllPendig,setSelectAllPendig]= useState(false);

  useEffect(
    () => {
      if (dataPurchaseDelivery.length!==countData || dataPurchaseDelivery.length<=rowsPerPage){
        setPage(0);
      }  
      setCountData(dataPurchaseDelivery.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataPurchaseDelivery.length) : 0);  
    },
    [dataPurchaseDelivery,page,setEmptyRows,rowsPerPage,countData]
  );

  const handleChangeOffset = (event) => {
    setCurrencyOffsetPurchaseDelivery(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModalDeleteRowTickets = (row) => {
    setRowEditPurchaseDelivery(row);
    setOpenDeleteRowPurchaseDelivery(true);
  };

  const openModalTicketsPDF = async (row) => {
    setRowEditPurchaseDelivery(row);
    setViewPDF(true);
  }; 

  const handleChangSelectAll = () => {
    setSelectAll(!selectAll);
    dataPurchaseDelivery.map((item,index)=>{
      return item.isFinished=!selectAll;
    });
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
   }))
  };

  const handleChangSelectAllPending = () => {
    setSelectAllPendig(!selectAllPendig);
    dataPurchaseDelivery.map((item,index)=>{
      return item.isPending=!selectAllPendig;
    });
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
   }))
  };

  const handleChangeEnabledRow = (row) => {
    var i =  dataPurchaseDelivery.indexOf( row );
    dataPurchaseDelivery.map((item,index)=>{
      if ( index===i ) {
          return item.isFinished=!item.isFinished;
      } else {
          return item.isFinished;
      }
    })
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
    }))
   };

   const handleChangeEnabledPendingRow = (row) => {
    var i =  dataPurchaseDelivery.indexOf( row );
    dataPurchaseDelivery.map((item,index)=>{
      if ( index===i ) {
          return item.isPending=!item.isPending;
      } else {
          return item.isPending;
      }
    })
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
    }))
   };

  const listItems= (
  stableSort(dataPurchaseDelivery, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keyData) => {
    return (
      <TableRow hover tabIndex={-1} key={keyData}>
         <TableCell align="left">
         <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.ownnumberpurchase}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#4e73df", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.purchasenote}
          </Typography>  
        </TableCell> 
        <TableCell align="center">
         <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {(row.numberticket!==undefined && row.numberticket!=="" && row.numberticket!=="0" && row.numberticket!==0)?row.numberticket:""}
          </Typography>
        </TableCell> 
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
          {row.datepurchase !== null &&  row.datepurchase !== ""
            ? new Date(row.datepurchase).toLocaleDateString(i18n.language === "es" ? "es-ES" : "en-US") 
            : ""}
          </Typography>
        </TableCell>
        
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.rel_trans_tradename}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.vehiclestore_licenseplate}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {row.ori_tras_tradename}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14}} >
            <NumericFormat 
              thousandSeparator={','} 
              decimalSeparator={'.'} 
              decimalScale= {2}
              fixedDecimalScale= {true}
              allowNegative= {false}
              id="totalpurchase" 
              name="totalpurchase"
              value={row.totalpurchase}
              style={{width:118, color:"#f5f5f5", background:"#bdbdbd",fontSize: 12, textAlign:'right', paddingRight:5}} 
              disabled
            />
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {i18n.language==='es'?row.payment_namees:row.payment_nameen}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
            {(row.formDIAssociates===undefined || row.formDIAssociates===null || row.formDIAssociates==="")?t('Text_NOT_REPORTING_DI'): row.formDIAssociates}
          </Typography>
        </TableCell>
        {(showFinishButtonPurchaseDelivery && row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <Checkbox checked={(typeOfList==="1" || typeOfList==="3")?row.isFinished:row.isPending} style={{color:"#4e73df", fontSize: 14}} onClick={(typeOfList==="1" || typeOfList==="3")?()=>handleChangeEnabledRow(row):()=>handleChangeEnabledPendingRow(row)}/>
          </TableCell>
        }
          
        {(showEditButtonPurchaseDelivery && row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <Link  to={typeOfList==="1"?`/purchasedeliverydetail/${row.id}`:typeOfList==="2"?`/purchasedeliverydetailended/${row.id}`:`/purchasedeliverydetailbegin/${row.id}`} >
              <IconButton  size="large"  style={{color:"#4e73df", fontSize: 14}} >
                <EditIcon/>
              </IconButton>
            </Link>
          </TableCell>
        }
         
         {(showPrintButtonPurchaseDelivery && row.id!=="" && row.id!==null ) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:"#e74a3b", fontSize: 14}} onClick={()=>openModalTicketsPDF(row)}>
              <PictureAsPdfIcon/>
            </IconButton>
          </TableCell>
        }

        {(showDeleteButtonPurchaseDelivery && row.id!=="" && row.id!==null) &&
          <TableCell align="center">
            <IconButton  size="large"  style={{color:row.enabled?"#e74a3b":"#ffc107", fontSize: 14}} onClick={()=>openModalDeleteRowTickets(row)}>
              {row.enabled?<DeleteIcon/>:<DeleteForeverIcon/>} 
            </IconButton>
          </TableCell>}
          {showStorePurchaseDelivery  &&
            <TableCell align="left">
              <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
                {row.tradename}
              </Typography>  
            </TableCell>
        }
        {showUserAccountPurchaseDelivery  &&
          <TableCell align="left">
            <Typography style={{color:"#000000", fontSize: 14, textDecorationLine: row.enabled?'':'line-through'}} >
              {row.username}
            </Typography>  
          </TableCell>
        } 
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}> 
        <EnhancedTableToolbar textHead={t(titleTable)} textReg={t('Text_Num_Reg')} numTotRegFilter={numTotRegFilter} numTotRegPurchaseDelivery={numTotRegPurchaseDelivery} textOf={t('Text_Of')}/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} showFinishButtonPurchaseDelivery={showFinishButtonPurchaseDelivery} showEditButtonPurchaseDelivery={showEditButtonPurchaseDelivery} showPrintButtonPurchaseDelivery={showPrintButtonPurchaseDelivery} showDeleteButtonPurchaseDelivery={showDeleteButtonPurchaseDelivery} showStorePurchaseDelivery={showStorePurchaseDelivery} showUserAccountPurchaseDelivery={showUserAccountPurchaseDelivery} selectAll={selectAll} selectAllPendig={selectAllPendig} handleChangSelectAll={handleChangSelectAll} handleChangSelectAllPending={handleChangSelectAllPending} typeOfList={typeOfList}/>
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={16} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffsetPurchaseDelivery && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffsetPurchaseDelivery"
                            name="currencyOffsetPurchaseDelivery"
                            value={currencyOffsetPurchaseDelivery}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffsetPurchaseDelivery
                            }}>
                            {rangeOffsetPurchaseDelivery.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={16}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingPurchaseDelivery.propTypes = {
  dataPurchaseDelivery: PropTypes.array.isRequired, 
  setFormPurchaseDelivery:PropTypes.func.isRequired,
  numTotRegFilter: PropTypes.number.isRequired,
  titleTable : PropTypes.string.isRequired, 
  setOpenDeleteRowPurchaseDelivery: PropTypes.func.isRequired, 
  setRowEditPurchaseDelivery: PropTypes.func.isRequired,   
  showFinishButtonPurchaseDelivery: PropTypes.bool.isRequired,
  showEditButtonPurchaseDelivery: PropTypes.bool.isRequired,  
  showPrintButtonPurchaseDelivery: PropTypes.bool.isRequired,  
  showDeleteButtonPurchaseDelivery: PropTypes.bool.isRequired,
  showStorePurchaseDelivery: PropTypes.bool.isRequired,
  showUserAccountPurchaseDelivery: PropTypes.bool.isRequired, 
  numTotRegPurchaseDelivery: PropTypes.number.isRequired,
  activeOffsetPurchaseDelivery: PropTypes.bool.isRequired,
  rangeOffsetPurchaseDelivery: PropTypes.array.isRequired,
  currencyOffsetPurchaseDelivery: PropTypes.number.isRequired,
  setCurrencyOffsetPurchaseDelivery: PropTypes.func.isRequired,
  setViewPDF: PropTypes.func.isRequired,
  typeOfList: PropTypes.string.isRequired 
};
  
export default TableSortingPurchaseDelivery;
