import React, {  Suspense,useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';   
import { TextField } from '@mui/material';
import { Button } from '@mui/material';  
import "css/generalSettings.css";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { Loading } from "components/utils/Loading"; 
import PropTypes from 'prop-types';  
import TableSortingPurchaseDelivery from "pages/purchaseDelivery/purchaseDeliveryFilterList/SortingTablesPurchaseDelivery";
import PurchaseDeliveryPDF from "pages/purchaseDelivery/generatePDF/PurchaseDeliveryPDF";

const initialFiltersPurchaseDelivery= {
  "idFrom":"",
  "idTo":"",
  "defaultStoreIdFrom":"",
  "defaultStoreIdTo":"",
  "useraccountIdFrom":"",
  "useraccountIdTo":"",
  "purchasenoteFrom":"",
  "purchasenoteTo":"",
  "datepurchaseFrom":null,
	"datepurchaseTo":null, 
  "vehiclestore_licenseplateFrom":"",
  "vehiclestore_licenseplateTo":"",
  "rel_trans_store_idFrom":"",
  "rel_trans_store_idTo":"",
  "ori_tras_store_idFrom":"",
  "ori_tras_store_idTo":"",
  "numberDIFROM":"",
  "numberDITO":"",
  "paymentFromES":"",
  "paymentToES":"",
  "paymentFromEN":"",
  "paymentToEN":"",
  
};
 
const initialStoresToSelect = [{
  "id": "",
  "documentid": "",
  "tradename": ""
}];

const initialPaymentToSelect = [{
  "id": "",
  "code": "",
  "namees": "",
  "nameen": "",
}];

const initialUserAccountToSelect = [{
  "id": "",
  "username":"",
  "firstName": "",
  "lastName1": "",
  "lastName2": "",
  "email": "",
  "aliasuser": ""
}];

const initialStatePurchseDelivery = [{
  "id" : "",
  "defaultStore_id": 0,
  "tradename": "",
  "useraccount_id": 0,
  "username": "",
  "defaultformatnumber": 1,
  "isPending": true,
  "isFinished" : false,
  "purchasenote":0, 
  "ownnumberpurchase":"", 
  "numberticket":0, 
  "vehiclestore_licenseplate" : "",
  "datepurchase" : null, 
  "rel_trans_tradename" : "",
  "ori_tras_tradename" : "",
  "totalpurchase" : 0 ,
  "enabled":"",
  "formDIAssociates": "",
  "payment_id" : 0 ,
  "payment_code": "",
  "payment_namees": "",
  "payment_nameen": ""
}];
            
const PurchaseDeliveryFilter = ({ applyFiltersPurchaseDelivery,setApplyFiltersPurchaseDelivery,showModalPurchasesDeliverySettings,setShowModalPurchasesDeliverySettings,typeOfList}) => {
  const {t,i18n} = useTranslation(['listDataPurchases']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DELETE_CODE_CREATE = useSelector((state) => state.formatDIUser.delete_code); 
  const DELETE_CODE_FORMDI = useSelector((state) => state.formatDIUser.delete_code_formdi); 
  const [dataPurchaseDelivery, setDataPurchaseDelivery] = useState(initialStatePurchseDelivery); 
  const [formPurchaseDelivery, setFormPurchaseDelivery] = useState(initialStatePurchseDelivery[0]);
  const [filtersPurchaseDelivery, setFiltersPurchaseDelivery]= useState(applyFiltersPurchaseDelivery);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegPurchaseDelivery,setNumTotRegPurchaseDelivery]= useState(0);
  const [errorsFiltersPurchaseDelivery, seterrorsFiltersPurchaseDelivery] = useState({});
  const [inputTextPurchaseDelivery, setInputTextPurchaseDelivery] = useState("");  
  const [openDeleteRowPurchaseDelivery,setOpenDeleteRowPurchaseDelivery]= useState(false); 
  const [openCreateBlankPurchaseDelivery,setOpenCreateBlankPurchaseDelivery]= useState(false); 
  const [openEditRowPurchaseDelivery,setOpenEditRowPurchaseDelivery]= useState(false);
  const [openEditFilterPurchaseDelivery,setOpenEditFilterPurchaseDelivery]= useState(false);
  const [rowEditPurchaseDelivery,setRowEditPurchaseDelivery]= useState(initialStatePurchseDelivery[0]);
  const [isLoadingPurchaseDelivery, setIsLoadingPurchaseDelivery] = useState(true);
  const [isLoadingErrorPurchaseDelivery, setIsLoadingErrorPurchaseDelivery] = useState(false);
  const [idCodeDelete,setIdCodeDelete]=useState(DELETE_CODE);
  const [idCreatePurchase,setIdCreatePurchase]=useState(DELETE_CODE_CREATE);
  const [viewPDF,setViewPDF]=useState(false);  
  const dispatch = useDispatch();
  const [activeOffsetPurchaseDelivery, setActiveOffsetPurchaseDelivery]= useState(false);
  const [currencyOffsetPurchaseDelivery, setCurrencyOffsetPurchaseDelivery] = useState(0);
  const [rangeOffsetPurchaseDelivery, setRangeOffsetPurchaseDelivery] = useState([{}]);
  const [storesToSelect,setStoresToSelect]= useState(initialStoresToSelect);
  const [userAccountsToSelect,setUserAccountsToSelect]= useState(initialUserAccountToSelect); 
  const [trasnporterToSelect,setTrasnporterToSelect]= useState(initialStoresToSelect); 
  const [tradenameToSelect,setTradenameToSelect]= useState(initialStoresToSelect); 
  const [paymenetTypeSelect,setPaymenetTypeSelect]= useState(initialPaymentToSelect); 
 
  const [refeshData,setRefeshData]=useState(false);
 
  const navigate = useNavigate(); 

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalPurchasesDeliverySettings) {
        setDataPurchaseDelivery(initialStatePurchseDelivery); 
        setFormPurchaseDelivery(initialStatePurchseDelivery[0]);
        setFiltersPurchaseDelivery(initialFiltersPurchaseDelivery);
        setNumTotRegFilter(0);
        setNumTotRegPurchaseDelivery(0);
        seterrorsFiltersPurchaseDelivery({});
        setInputTextPurchaseDelivery(""); 
        setOpenDeleteRowPurchaseDelivery(false); 
        setOpenCreateBlankPurchaseDelivery(false);
        setOpenEditRowPurchaseDelivery(false);
        setOpenEditFilterPurchaseDelivery(false);
        setRowEditPurchaseDelivery(initialStatePurchseDelivery[0]);
        setIsLoadingPurchaseDelivery(false);
        setIsLoadingErrorPurchaseDelivery(false);
        setIdCodeDelete(DELETE_CODE);
        setIdCreatePurchase(DELETE_CODE_CREATE);
        setViewPDF(false);
        setRefeshData(false);
        setActiveOffsetPurchaseDelivery(false);
        setCurrencyOffsetPurchaseDelivery(0);
        setRangeOffsetPurchaseDelivery([{}]);
        setStoresToSelect(initialStoresToSelect);
        setUserAccountsToSelect(initialUserAccountToSelect); 
        setShowModalPurchasesDeliverySettings(false);
        setTrasnporterToSelect(initialStoresToSelect);
        setTradenameToSelect(initialStoresToSelect);
        setPaymenetTypeSelect(initialPaymentToSelect);
        navigate("/home");
        return () => {};
      }
    },
    [
      setDataPurchaseDelivery,  
      setFormPurchaseDelivery,
      setFiltersPurchaseDelivery,
      setNumTotRegFilter,
      setNumTotRegPurchaseDelivery,
      seterrorsFiltersPurchaseDelivery,
      setInputTextPurchaseDelivery, 
      setOpenDeleteRowPurchaseDelivery, 
      setOpenCreateBlankPurchaseDelivery,
      setOpenEditRowPurchaseDelivery,
      setOpenEditFilterPurchaseDelivery,
      setRowEditPurchaseDelivery,
      setIsLoadingPurchaseDelivery,
      setIsLoadingErrorPurchaseDelivery,
      setIdCodeDelete,
      setIdCreatePurchase,
      setViewPDF,
      setRefeshData,
      setActiveOffsetPurchaseDelivery,
      setCurrencyOffsetPurchaseDelivery,
      setRangeOffsetPurchaseDelivery,
      setStoresToSelect,
      setUserAccountsToSelect, 
      setTrasnporterToSelect,
      setTradenameToSelect,
      setPaymenetTypeSelect,
      setShowModalPurchasesDeliverySettings,
      showModalPurchasesDeliverySettings, 
      navigate,
      DELETE_CODE,
      DELETE_CODE_CREATE]
  );

  const closeModalPurchaseDelivery = () => {    
    setDataPurchaseDelivery(initialStatePurchseDelivery); 
    setFormPurchaseDelivery(initialStatePurchseDelivery[0]);
    setFiltersPurchaseDelivery(initialFiltersPurchaseDelivery);
    setNumTotRegFilter(0);
    setNumTotRegPurchaseDelivery(0);
    seterrorsFiltersPurchaseDelivery({});
    setInputTextPurchaseDelivery(""); 
    setOpenDeleteRowPurchaseDelivery(false); 
    setOpenCreateBlankPurchaseDelivery(false);
    setOpenEditRowPurchaseDelivery(false);
    setOpenEditFilterPurchaseDelivery(false);
    setRowEditPurchaseDelivery(initialStatePurchseDelivery[0]);
    setIsLoadingPurchaseDelivery(false);
    setIsLoadingErrorPurchaseDelivery(false);
    setIdCodeDelete(DELETE_CODE);
    setIdCreatePurchase(DELETE_CODE_CREATE);
    setViewPDF(false);
    setRefeshData(false);
    setActiveOffsetPurchaseDelivery(false);
    setCurrencyOffsetPurchaseDelivery(0);
    setRangeOffsetPurchaseDelivery([{}]);
    setStoresToSelect(initialStoresToSelect);
    setUserAccountsToSelect(initialUserAccountToSelect); 
    setTrasnporterToSelect(initialStoresToSelect);
    setTradenameToSelect(initialStoresToSelect);
    setPaymenetTypeSelect(initialPaymentToSelect);
    setShowModalPurchasesDeliverySettings(false);
    navigate("/home");
    return () => {};
  };

  const handleFinishSelectedPurchaseDelivery = (e) => {
    let isMounted = true;
    if (e.key !== 'Escape') {

      const fetchnumTotRegPurchaseDelivery = async () => {
        setIsLoadingPurchaseDelivery(true);
        try {
          const getData  = (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master')))
          ? await helpHttp().get2(ENDPOINT,`purchasedelivery/getinformationpurchasedelivery/`,token,(typeOfList==="1" || typeOfList==="3")?false:true)
          : (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master'))?
              await helpHttp().get8(ENDPOINT,`purchasedelivery/getInformationpurchasedeliverywithstore`,token,defaultStore.id,(typeOfList==="1" || typeOfList==="3")?false:true):
              await helpHttp().get7(ENDPOINT,`purchasedelivery/getinformationpurchasedeliverywithstoreanduseraccount`,token,defaultStore.id,useraccount_id,(typeOfList==="1" || typeOfList==="3")?false:true))
          if (getData.err || getData.message === "Load failed") {
            if (isMounted) {
              setDataPurchaseDelivery(initialStatePurchseDelivery);
              if (getData.message==="Load failed"){
                dispatch(warningErrorAction(t('Text_ConnectionFailied')));
              }else {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              }  
            }
          } else if (isMounted) {
            setNumTotRegPurchaseDelivery(getData);
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoadingPurchaseDelivery(false);
        }
      };

      const fetchnumTotRegFilter = async () => {
        setIsLoadingPurchaseDelivery(true);
        try {
          const getData = 
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master')))?
          await helpHttp().post4(ENDPOINT,`purchasedelivery/getcountfilterpurchasedelivery`,token,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true,applyFiltersPurchaseDelivery):
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master')))?
          await helpHttp().post4(ENDPOINT,`purchasedelivery/getcountfilterpurchasedelivery`,token,"-1",useraccount_id,(typeOfList==="1" || typeOfList==="3")?false:true,applyFiltersPurchaseDelivery):
          await helpHttp().post4(ENDPOINT,`purchasedelivery/getcountfilterpurchasedelivery`,token,defaultStore.id,useraccount_id,(typeOfList==="1" || typeOfList==="3")?false:true,applyFiltersPurchaseDelivery);
          if (getData.err || getData.message === "Load failed") {
            if (isMounted) {
              setDataPurchaseDelivery(initialStatePurchseDelivery);
              if (getData.message==="Load failed"){
                dispatch(warningErrorAction(t('Text_ConnectionFailied')));
              }else {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              } 
            }
          } else if (isMounted) {
            setNumTotRegFilter(getData);
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoadingPurchaseDelivery(false);
        } 
      }; 

      const fetchDataPurchaseDelivery = async () => {
        try {
          const getData = 
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master')))?
          await helpHttp().post6(ENDPOINT,`purchasedelivery/getpurchasedelivery`,token,currencyOffsetPurchaseDelivery,LIMITPAGESIZE,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true,applyFiltersPurchaseDelivery):
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master')))?
          await helpHttp().post6(ENDPOINT,`purchasedelivery/getpurchasedelivery`,token,currencyOffsetPurchaseDelivery,LIMITPAGESIZE,"-1",useraccount_id,(typeOfList==="1" || typeOfList==="3")?false:true,applyFiltersPurchaseDelivery):
          await helpHttp().post6(ENDPOINT,`purchasedelivery/getpurchasedelivery`,token,defaultStore.id,useraccount_id,(typeOfList==="1" || typeOfList==="3")?false:true,applyFiltersPurchaseDelivery);
          if (getData.err) {
            dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
            setDataPurchaseDelivery(getData);
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
      };

      const fetchData = async () => {
        setIsLoadingPurchaseDelivery(true);
        try {
          const getData = (typeOfList==="1" || typeOfList==="3")
          ? await helpHttp().put(ENDPOINT, `purchasedelivery/finishpurchasedelivery`, token, dataPurchaseDelivery)
          :await helpHttp().put(ENDPOINT, `purchasedelivery/returnpurchasedelivery`, token, dataPurchaseDelivery);
          if (getData.status === "OK") {
            if (isMounted) {
              dispatch(successErrorAction((typeOfList==="1" || typeOfList==="3")?t('Text_Finished'):t('Text_Pending'))); 
              handleCloseDataEditPurchaseDelivery();
              fetchnumTotRegPurchaseDelivery();
              fetchnumTotRegFilter();
              fetchDataPurchaseDelivery();
            }
          } else {
            if (isMounted) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoadingPurchaseDelivery(false);
        }  
        if (isMounted && isLoadingPurchaseDelivery!==undefined) {
          setIsLoadingPurchaseDelivery(false);
        } 
      }   
      fetchData();
    } 
    setIsLoadingPurchaseDelivery(false);
    handleCloseDataEditPurchaseDelivery();
  };
 
  const openCreateOrEditDataPurchaseDelivery = () => {
    setInputTextPurchaseDelivery(""); 
    setOpenEditFilterPurchaseDelivery(!openEditFilterPurchaseDelivery);
    navigate(typeOfList==="1"?"/purchasedeliverydetail/0":typeOfList==="2"?"/purchasedeliverydetailended/0":`/purchasedeliverydetailbegin/0`);
  };

   
  const openEditFiltersPurchaseDelivery = () => {
      setFiltersPurchaseDelivery(applyFiltersPurchaseDelivery);
      setOpenEditFilterPurchaseDelivery(!openEditFilterPurchaseDelivery);
  };

  const handleSubmitFilterEditPurchaseDelivery = () => {
    seterrorsFiltersPurchaseDelivery(validateFormFilterPurchaseDelivery());
    if (Object.keys(errorsFiltersPurchaseDelivery).length === 0) {
      setApplyFiltersPurchaseDelivery(filtersPurchaseDelivery);
      setFiltersPurchaseDelivery(initialStatePurchseDelivery);
      seterrorsFiltersPurchaseDelivery({}); 
      setOpenEditFilterPurchaseDelivery(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }

  const handleDeleteFiltersPurchaseDelivery = () => {
    seterrorsFiltersPurchaseDelivery({});
    setFiltersPurchaseDelivery(initialFiltersPurchaseDelivery);
    setApplyFiltersPurchaseDelivery(initialFiltersPurchaseDelivery); 
    setOpenEditFilterPurchaseDelivery(!openEditFilterPurchaseDelivery);
  };

  const handleCancelFiltersPurchaseDelivery = () => {
    seterrorsFiltersPurchaseDelivery({});
    setFiltersPurchaseDelivery(initialFiltersPurchaseDelivery); 
    setOpenEditFilterPurchaseDelivery(!openEditFilterPurchaseDelivery);
  };
 
  const handleChangeSelectDatePurchaseDelivery = (event) => {
    setFiltersPurchaseDelivery(filtersPurchaseDelivery => ({
      ...filtersPurchaseDelivery,
      datepurchaseFrom: event!==null?new Date(event):null,
      datepurchaseTo:null
    }));
  };

  const handleBlurSelectDatePurchaseDeliveryFrom = (e) => {
    handleChangeSelectDatePurchaseDelivery(e);
    seterrorsFiltersPurchaseDelivery(validateFormFilterPurchaseDelivery());
  };

  const handleChangeSelectDatePurchaseDeliveryTo = (event) => {
    setFiltersPurchaseDelivery(filtersPurchaseDelivery => ({
      ...filtersPurchaseDelivery,
      datepurchaseTo: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDatePurchaseDeliveryTo = (e) => {
    handleChangeSelectDatePurchaseDeliveryTo(e);
    seterrorsFiltersPurchaseDelivery(validateFormFilterPurchaseDelivery());
  };
    
  const handleChangeFilterEditPurchaseDelivery = (e) => {
    const { name, value } = e.target;
    setFiltersPurchaseDelivery(filtersPurchaseDelivery => ({
      ...filtersPurchaseDelivery,
      [name]: value
    }));
  };

  const handleBlurFilterEditPurchaseDelivery = (e) => {
    seterrorsFiltersPurchaseDelivery(validateFormFilterPurchaseDelivery());
    handleChangeFilterEditPurchaseDelivery(e);
  };

  const toggleDrawerFilterEditPurchaseDelivery = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditPurchaseDelivery();
    }
  };

  const validateFormFilterPurchaseDelivery = () => {

    let errorsFiltersPurchaseDelivery = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersPurchaseDelivery.idFrom!=="" & !regexNumber.test(filtersPurchaseDelivery.idFrom)) {
      errorsFiltersPurchaseDelivery.idFrom = 'Text_TextNoNumber';
    }
    if (filtersPurchaseDelivery.idTo!=="" & !regexNumber.test(filtersPurchaseDelivery.idTo)) {
      errorsFiltersPurchaseDelivery.idTo = 'Text_TextNoNumber';
    }
    if (filtersPurchaseDelivery.idFrom!=="" & !errorsFiltersPurchaseDelivery.idFrom!==undefined){
      if (filtersPurchaseDelivery.idTo!=="" & !errorsFiltersPurchaseDelivery.idTo!==undefined){
        if (parseInt(filtersPurchaseDelivery.idTo)<parseInt(filtersPurchaseDelivery.idFrom)){
          errorsFiltersPurchaseDelivery.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (filtersPurchaseDelivery.numberDIFROM!=="" & !regexNumber.test(filtersPurchaseDelivery.numberDIFROM)) {
      errorsFiltersPurchaseDelivery.numberDIFROM = 'Text_TextNoNumber';
    }
    if (filtersPurchaseDelivery.numberDITO!=="" & !regexNumber.test(filtersPurchaseDelivery.numberDITO)) {
      errorsFiltersPurchaseDelivery.numberDITO = 'Text_TextNoNumber';
    }
    if (filtersPurchaseDelivery.numberDIFROM!=="" & !errorsFiltersPurchaseDelivery.numberDIFROM!==undefined){
      if (filtersPurchaseDelivery.numberDITO!=="" & !errorsFiltersPurchaseDelivery.numberDITO!==undefined){
        if (parseInt(filtersPurchaseDelivery.numberDITO)<parseInt(filtersPurchaseDelivery.numberDIFROM)){
          errorsFiltersPurchaseDelivery.numberDITO = 'Text_TextGreater_FormDI';
        }
      }
    }
    
    if (regexInvalidCharacters.test(filtersPurchaseDelivery.datepurchaseFrom)) {
      errorsFiltersPurchaseDelivery.datepurchaseFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersPurchaseDelivery.datepurchaseTo)) {
      errorsFiltersPurchaseDelivery.datepurchaseTo = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(filtersPurchaseDelivery.vehiclestore_licenseplateFrom)) {
      errorsFiltersPurchaseDelivery.vehiclestore_licenseplateFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersPurchaseDelivery.vehiclestore_licenseplateTo)) {
      errorsFiltersPurchaseDelivery.vehiclestore_licenseplateTo = 'Text_TextNoValid';
    }
   
    if (filtersPurchaseDelivery.defaultStoreIdTo!=="" & filtersPurchaseDelivery.defaultStoreIdTo<filtersPurchaseDelivery.defaultStoreIdFrom){
      errorsFiltersPurchaseDelivery.defaultStoreIdTo = 'Text_TextGreater_Store';
    }

    if (filtersPurchaseDelivery.useraccountIdTo!=="" & filtersPurchaseDelivery.useraccountIdTo<filtersPurchaseDelivery.useraccountIdFrom){
      errorsFiltersPurchaseDelivery.useraccountIdTo = 'Text_TextGreater_UserAccount';
    }
    
    return errorsFiltersPurchaseDelivery;
  }  

  const filteredDataPurchaseDelivery = dataPurchaseDelivery.filter((el) => {
    if (inputTextPurchaseDelivery === '') {
        return el;
    }
    else {
        let dataTranslate=el.id+el.purchasenote+el.vehiclestore_licenseplate+el.rel_trans_tradename+el.ori_tras_tradename;
        return dataTranslate.toLowerCase().includes(inputTextPurchaseDelivery);
    }
  })

  const openCreateNewBlankPurchaseDelivery = () => { 
    setOpenCreateBlankPurchaseDelivery(true);
  };

  const handleCancelCreateBlankPurchaseDelivery = () => {
    setIdCreatePurchase(DELETE_CODE_CREATE);
    setOpenCreateBlankPurchaseDelivery(false);
  };

  const handleChangeCreateBlankPurchaseDelivery = (e) => {
    const value  = e.target.value;
    setIdCreatePurchase(value);
  };

  const handleSubmitCreateBlankPurchaseDelivery = (e) => {
    if (idCreatePurchase !== DELETE_CODE_CREATE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    let isMounted = true;
    if (e.key !== 'Escape') {
      const fetchData = async () => {
        setIsLoadingPurchaseDelivery(true);
        try {
          const getData = await helpHttp().put4(ENDPOINT,`purchasedelivery/saveblankpurchasedelivery`,token,defaultStore.id,useraccount_id);
          if (getData.status === "OK") {
            if (isMounted) {
              dispatch(successErrorAction(t('Text_ValidCode')));
              dispatch(successErrorAction(t('Text_SaveData_Blank')));
              setRefeshData(true);
              handleCloseDataEditPurchaseDelivery();
            }
          } else {
            if (isMounted) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoadingPurchaseDelivery(false);
        }  
        if (isMounted && isLoadingPurchaseDelivery!==undefined) {
          setIsLoadingPurchaseDelivery(false);
        } 
      };
      fetchData();
      
    } else {
      dispatch(warningErrorAction(t('Text_InvalidId')));
    }
    setIdCreatePurchase(DELETE_CODE_CREATE);
    setIsLoadingErrorPurchaseDelivery(false);
    handleCloseDataEditPurchaseDelivery(); 
  };

  let inputHandlerPurchaseDelivery  = (e) => {
      var lowerCase = e.target.value.toLowerCase();
      setInputTextPurchaseDelivery(lowerCase);
  };

  const handleChangeDeleteRowPurchaseDelivery = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRowPurchaseDelivery = () => {
    setRowEditPurchaseDelivery(initialStatePurchseDelivery[0]);
    setIsLoadingErrorPurchaseDelivery(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowPurchaseDelivery(false);
  };
 
  const handleCloseDeleteRowPurchaseDelivery = (e) => {
    if (idCodeDelete !== DELETE_CODE_FORMDI) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    let isMounted = true;
    if (e.key !== 'Escape') {
      if (rowEditPurchaseDelivery.id!=='') {      
        const fetchData = async () => {
          setIsLoadingPurchaseDelivery(true);
          try {
            const getData = await helpHttp().del(ENDPOINT,`purchasedelivery/deleteresepurchasedelivery  `,token,rowEditPurchaseDelivery);
            if (getData.status === "OK") {
              if (isMounted) {
                dispatch(successErrorAction(t('Text_ValidCode')));
                dispatch(successErrorAction(t('Text_DeleteField')));
                setRefeshData(true);
                handleCloseDataEditPurchaseDelivery();
              }
            } else {
              if (isMounted) {
                dispatch(warningErrorAction(t(getData.cause)));
              }
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            setIsLoadingPurchaseDelivery(false);
          }  
          if (isMounted && isLoadingPurchaseDelivery!==undefined) {
            setIsLoadingPurchaseDelivery(false);
          } 
        };
        fetchData();
      
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowPurchaseDelivery(false);
    setIsLoadingErrorPurchaseDelivery(false);
    handleCloseDataEditPurchaseDelivery(); 
  };
 
  const handleCloseDataEditPurchaseDelivery = () => { 
    setOpenEditRowPurchaseDelivery(false);
    setRowEditPurchaseDelivery(openEditRowPurchaseDelivery?rowEditPurchaseDelivery:initialStatePurchseDelivery[0]);
    setIsLoadingPurchaseDelivery(false);
    setIsLoadingErrorPurchaseDelivery(false);
    toggleDrawerFilterEditPurchaseDelivery(false); 
    setRefeshData(true); 
	};
  
  useEffect(() => {
    if (numTotRegPurchaseDelivery > Number(LIMITPAGESIZE)) {
      setActiveOffsetPurchaseDelivery(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegPurchaseDelivery; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetPurchaseDelivery(newRangeOffset);
    } else {
      setCurrencyOffsetPurchaseDelivery(0);
      setActiveOffsetPurchaseDelivery(false);
      setRangeOffsetPurchaseDelivery([{}]);
    }
  }, [numTotRegPurchaseDelivery, LIMITPAGESIZE]);

  useEffect(() => {
    if (showModalPurchasesDeliverySettings){
      setFormPurchaseDelivery(formPurchaseDelivery => ({
        ...formPurchaseDelivery,
        id : rowEditPurchaseDelivery.id!==null?rowEditPurchaseDelivery.id:"",
        defaultStore_id : rowEditPurchaseDelivery.defaultStore_id!==null?rowEditPurchaseDelivery.defaultStore_id:0,
        tradename :rowEditPurchaseDelivery.tradename!==null?rowEditPurchaseDelivery.tradename:"",
        useraccount_id :rowEditPurchaseDelivery.useraccount_id!==null?rowEditPurchaseDelivery.useraccount_id:0,
        username :rowEditPurchaseDelivery.username!==null?rowEditPurchaseDelivery.username:"",
        defaultformatnumber :rowEditPurchaseDelivery.defaultformatnumber!==null?rowEditPurchaseDelivery.defaultformatnumber:0,
        purchasenote :rowEditPurchaseDelivery.purchasenote!==null?rowEditPurchaseDelivery.purchasenote:"",
        vehiclestore_licenseplate :rowEditPurchaseDelivery.vehiclestore_licenseplate!==null?rowEditPurchaseDelivery.vehiclestore_licenseplate:"",
        datepurchase :rowEditPurchaseDelivery.datepurchase!==null?rowEditPurchaseDelivery.datepurchase:"",
        rel_trans_tradename :rowEditPurchaseDelivery.rel_trans_tradename!==null?rowEditPurchaseDelivery.rel_trans_tradename:"",
        ori_tras_tradename :rowEditPurchaseDelivery.ori_tras_tradename!==null?rowEditPurchaseDelivery.ori_tras_tradename:"",
        totalpurchase :rowEditPurchaseDelivery.totalpurchase!==null?rowEditPurchaseDelivery.totalpurchase:0,
        payment_id:rowEditPurchaseDelivery.payment_id!==null?rowEditPurchaseDelivery.payment_id:0,
        payment_code:rowEditPurchaseDelivery.payment_code!==null?rowEditPurchaseDelivery.payment_code:"",
        payment_namees:rowEditPurchaseDelivery.payment_namees!==null?rowEditPurchaseDelivery.payment_namees:"",
        payment_nameen:rowEditPurchaseDelivery.payment_nameen!==null?rowEditPurchaseDelivery.payment_nameen:"",
      }));
    }else{
      setFormPurchaseDelivery(initialStatePurchseDelivery[0]);
    }
  },[rowEditPurchaseDelivery,showModalPurchasesDeliverySettings]);


  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;

    const fetchDataStore = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT,`purchasedelivery/storesorderbytradename`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setStoresToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataUserAccount = async () => {
      try {
        const getData =(roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master')))?
            await helpHttp().get2(ENDPOINT,`purchasedelivery/useraccountsorderbyusername/`,token,"-1"):
            await helpHttp().get2(ENDPOINT,`purchasedelivery/useraccountsorderbyusername/`,token,defaultStore.id);
      if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setUserAccountsToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    const fetchDataTransporter = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `purchasedelivery/getlisttransporterpurchasedelivery/`, token,false);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTrasnporterToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    const fetchDataPayment = async () => {
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `paymenttype/paymenttypesorderbynamees`, token)
        : await helpHttp().get(ENDPOINT, `paymenttype/paymenttypesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setPaymenetTypeSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    const fetchDataTradename = async () => {
      try {
        const getData = 
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master')))?
          await helpHttp().get7(ENDPOINT,`purchasedelivery/getlisttradenamepurchasedelivery`,token,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true):
          (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master')))?
          await helpHttp().get7(ENDPOINT,`purchasedelivery/getlisttradenamepurchasedelivery`,token,"-1",useraccount_id,(typeOfList==="1" || typeOfList==="3")?false:true):
          await helpHttp().get7(ENDPOINT,`purchasedelivery/getlisttradenamepurchasedelivery`,token,defaultStore.id,useraccount_id,(typeOfList==="1" || typeOfList==="3")?false:true);
 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTradenameToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };   

    if (showModalPurchasesDeliverySettings ) { 
      fetchDataTransporter(); 
      fetchDataTradename();
      fetchDataPayment();
      if (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master'))){
        fetchDataStore();
      }
      if (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master'))){
        fetchDataUserAccount();
      } 
    }
    return () => { isMounted = false };

  }, [ENDPOINT,t,token,dispatch,i18n,roles,applyFiltersPurchaseDelivery,openEditFilterPurchaseDelivery,showModalPurchasesDeliverySettings,defaultStore.id,useraccount_id,typeOfList]);
   
  useEffect(() => {
    let isMounted = true;

    const fetchnumTotRegPurchaseDelivery = async () => {
      setIsLoadingPurchaseDelivery(true);
      try {
        const getData  = (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master')))
        ? await helpHttp().get2(ENDPOINT,`purchasedelivery/getinformationpurchasedelivery/`,token,(typeOfList==="1" || typeOfList==="3")?false:true)
        : (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master'))?
            await helpHttp().get8(ENDPOINT,`purchasedelivery/getInformationpurchasedeliverywithstore`,token,defaultStore.id,(typeOfList==="1" || typeOfList==="3")?false:true):
            await helpHttp().get7(ENDPOINT,`purchasedelivery/getinformationpurchasedeliverywithstoreanduseraccount`,token,defaultStore.id,useraccount_id,(typeOfList==="1" || typeOfList==="3")?false:true))
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataPurchaseDelivery(initialStatePurchseDelivery);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            }  
          }
        } else if (isMounted) {
          setNumTotRegPurchaseDelivery(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingPurchaseDelivery(false);
      }
    };

    const fetchnumTotRegFilter = async () => {
      setIsLoadingPurchaseDelivery(true);
      try {
        const getData = 
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master')))?
        await helpHttp().post4(ENDPOINT,`purchasedelivery/getcountfilterpurchasedelivery`,token,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true,applyFiltersPurchaseDelivery):
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master')))?
        await helpHttp().post4(ENDPOINT,`purchasedelivery/getcountfilterpurchasedelivery`,token,"-1",useraccount_id,(typeOfList==="1" || typeOfList==="3")?false:true,applyFiltersPurchaseDelivery):
        await helpHttp().post4(ENDPOINT,`purchasedelivery/getcountfilterpurchasedelivery`,token,defaultStore.id,useraccount_id,(typeOfList==="1" || typeOfList==="3")?false:true,applyFiltersPurchaseDelivery);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataPurchaseDelivery(initialStatePurchseDelivery);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } 
          }
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoadingPurchaseDelivery(false);
      } 
    }; 
    
    const fetchDataPurchaseDelivery = async () => {
      try {
        const getData = 
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master')))?
        await helpHttp().post6(ENDPOINT,`purchasedelivery/getpurchasedelivery`,token,currencyOffsetPurchaseDelivery,LIMITPAGESIZE,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true,applyFiltersPurchaseDelivery):
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master')))?
        await helpHttp().post6(ENDPOINT,`purchasedelivery/getpurchasedelivery`,token,currencyOffsetPurchaseDelivery,LIMITPAGESIZE,"-1",useraccount_id,(typeOfList==="1" || typeOfList==="3")?false:true,applyFiltersPurchaseDelivery):
        await helpHttp().post6(ENDPOINT,`purchasedelivery/getpurchasedelivery`,token,defaultStore.id,useraccount_id,(typeOfList==="1" || typeOfList==="3")?false:true,applyFiltersPurchaseDelivery);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataPurchaseDelivery(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
 
    if (showModalPurchasesDeliverySettings || refeshData){
      fetchnumTotRegPurchaseDelivery();
      fetchnumTotRegFilter(); 
      fetchDataPurchaseDelivery();
      setIsLoadingPurchaseDelivery(false);
      setRefeshData(false);
    }  
    return () => { isMounted = false; };
  }, [ENDPOINT,t,roles,token,dispatch,i18n.language,LIMITPAGESIZE,applyFiltersPurchaseDelivery,showModalPurchasesDeliverySettings,currencyOffsetPurchaseDelivery,defaultStore.id,useraccount_id ,openDeleteRowPurchaseDelivery,setOpenDeleteRowPurchaseDelivery,setOpenEditRowPurchaseDelivery,openEditRowPurchaseDelivery,rowEditPurchaseDelivery,setRowEditPurchaseDelivery,setIsLoadingPurchaseDelivery,defaultStore,typeOfList,refeshData ]);
     
   return (
    <>
       {showModalPurchasesDeliverySettings ? ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className={(typeOfList==="1" || typeOfList==="3")?"hANewStyle mb-2":"hBNewStyle mb-2"}>
              {isLoadingErrorPurchaseDelivery?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingPurchaseDelivery?<CircularProgress color={openDeleteRowPurchaseDelivery?"warning":"primary"}/>:<i className="fas fa-highlighter text-warning"></i>} {(typeOfList==="1" || typeOfList==="3")?t('Text_PurchaseDelivery_Table'):t('Text_PurchaseDeliveryEnded_Table')}</h1>
              {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_Create')) ?
                <div>
                  {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_Ended'))?
                    <div>
                      <button type="button" className="buttonCreate" onClick={openCreateOrEditDataPurchaseDelivery}>{t('Button_Create')}</button><> </>
                      <button type="button" className="buttonFinishPurchase" onClick={openCreateNewBlankPurchaseDelivery}>{t('Button_Create_BLANK')}</button><> </>
                      <button type="button" className="buttonFinishPurchase" onClick={handleFinishSelectedPurchaseDelivery}>{(typeOfList==="1" || typeOfList==="3")?t('Button_Finish'):t('Button_Pending')}</button><> </>
                      <button type="button" className="buttonBack" onClick={closeModalPurchaseDelivery}>{t('Button_Back')}</button><> </>
                    </div>:
                    <div>
                      <button type="button" className="buttonCreate" onClick={openCreateOrEditDataPurchaseDelivery}>{t('Button_Create')}</button><> </>
                      <button type="button" className="buttonFinishPurchase" onClick={openCreateNewBlankPurchaseDelivery}>{t('Button_Create_BLANK')}</button><> </>
                      <button type="button" className="buttonBack" onClick={closeModalPurchaseDelivery}>{t('Button_Back')}</button><> </>
                    </div>
                  }
                </div>:
                <div>
                  {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_Ended'))?
                    <div>
                      <button type="button" className="buttonFinishPurchase" onClick={handleFinishSelectedPurchaseDelivery}>{(typeOfList==="1" || typeOfList==="3")?t('Button_Finish'):t('Button_Pending')}</button><> </>
                      <button type="button" className="buttonBack" onClick={closeModalPurchaseDelivery}>{t('Button_Back')}</button><> </>
                    </div>:
                    <div>
                      <button type="button" className="buttonBack" onClick={closeModalPurchaseDelivery}>{t('Button_Back')}</button><> </>
                    </div>
                  } 
                </div>
              }
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputTextPurchaseDelivery}
              placeholder={t('Text_PurchaseDelivery_Search')} 
              onChange={inputHandlerPurchaseDelivery }
              fullWidth
              label={t('Text_PurchaseDelivery_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyle mb-2">
            {t('Text_Filter')}&nbsp;
                <button type="button" className="buttonFilter" onClick={openEditFiltersPurchaseDelivery}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPurchaseDelivery.idFrom!=="" || applyFiltersPurchaseDelivery.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Contacts_Store_Master"))&&
                  <>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersPurchaseDelivery}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPurchaseDelivery.defaultStoreIdFrom!=="" || applyFiltersPurchaseDelivery.defaultStoreIdTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Store')}</button><> </>
                  </>
                }
                 {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Contacts_User_Master"))&&
                  <>
                    <button type="button" className="buttonFilter" onClick={openEditFiltersPurchaseDelivery}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPurchaseDelivery.useraccountIdFrom!=="" || applyFiltersPurchaseDelivery.useraccountIdTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_UserAccount')}</button><> </>
                  </>
                }
                <button type="button" className="buttonFilter" onClick={openEditFiltersPurchaseDelivery}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPurchaseDelivery.purchasenoteFrom!=="" || applyFiltersPurchaseDelivery.purchasenoteTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_NumberPurchase')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersPurchaseDelivery}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPurchaseDelivery.datepurchaseFrom!==null|| applyFiltersPurchaseDelivery.datepurchaseTo!==null)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Dates')}</button><> </>                 
                <button type="button" className="buttonFilter" onClick={openEditFiltersPurchaseDelivery }><i className="fas fa-solid fa-circle" style={{color:(applyFiltersPurchaseDelivery.numberDIFROM!=="" || applyFiltersPurchaseDelivery.numberDITO!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Filter_FormDI')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFiltersPurchaseDelivery }><i className="fas fa-solid fa-circle" style={{color:( 
                     applyFiltersPurchaseDelivery.vehiclestore_licenseplateFrom
                      || applyFiltersPurchaseDelivery.vehiclestore_licenseplateTo
                      || applyFiltersPurchaseDelivery.rel_trans_store_idFrom
                      ||  applyFiltersPurchaseDelivery.rel_trans_store_idTo!=="" 
                      || applyFiltersPurchaseDelivery.ori_tras_store_idFrom!==""
                      || applyFiltersPurchaseDelivery.ori_tras_store_idTo!==""
                      || applyFiltersPurchaseDelivery.paymentFromES!==""
                      || applyFiltersPurchaseDelivery.paymentToES!==""
                      || applyFiltersPurchaseDelivery.paymentFromEN!==""
                      || applyFiltersPurchaseDelivery.paymentToEN!==""
                      )?"#32CD32":"#FFFFFF"}}></i> {t('Text_Others_Filters')}</button><> </> 
              
            </h1>
          </div>
          <br/>

          {!isLoadingPurchaseDelivery ?
            <>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingPurchaseDelivery
                    dataPurchaseDelivery={filteredDataPurchaseDelivery}
                    setFormPurchaseDelivery={setFormPurchaseDelivery}
                    numTotRegFilter={ numTotRegFilter}
                    titleTable={t('Text_PurchaseDelivery_Table')}
                    setOpenDeleteRowPurchaseDelivery={setOpenDeleteRowPurchaseDelivery} 
                    setRowEditPurchaseDelivery={setRowEditPurchaseDelivery}
                    showFinishButtonPurchaseDelivery={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_Ended'))?true:false}
                    showEditButtonPurchaseDelivery={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_Edit'))?true:false}
                    showPrintButtonPurchaseDelivery={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_Print'))?true:false}
                    showDeleteButtonPurchaseDelivery={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_Delete'))?true:false}
                    showStorePurchaseDelivery={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master'))?true:false}
                    showUserAccountPurchaseDelivery={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master'))?true:false}
                    numTotRegPurchaseDelivery={numTotRegPurchaseDelivery}
                    activeOffsetPurchaseDelivery={activeOffsetPurchaseDelivery}
                    rangeOffsetPurchaseDelivery={rangeOffsetPurchaseDelivery}
                    currencyOffsetPurchaseDelivery={currencyOffsetPurchaseDelivery}
                    setCurrencyOffsetPurchaseDelivery={setCurrencyOffsetPurchaseDelivery}
                    setViewPDF={setViewPDF} 
                    typeOfList={typeOfList}
                    formPurchaseDelivery={formPurchaseDelivery}
                    >
                  </TableSortingPurchaseDelivery>
                </div>             
              </div>
              
              {openEditFilterPurchaseDelivery &&
               <div>
               <SwipeableDrawer
                 anchor='right'
                 open={true}
                 onClose={toggleDrawerFilterEditPurchaseDelivery(false)}
                 onOpen={toggleDrawerFilterEditPurchaseDelivery(true)}>
                 <Container >
                   <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                     <br/>
                       <div id="myDIV">
                         <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                       </div>
                       <hr className="sidebar-divider d-none d-md-block"/>
                       <form className="createfilterpurchasedelivery" onSubmit={handleCancelFiltersPurchaseDelivery}>  
                         <div className="form-floating mb-3 px-4 primary">
                           <div className="form-group">
                             <TextField
                               id="idFrom" 
                               name="idFrom"
                               value={filtersPurchaseDelivery.idFrom}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersPurchaseDelivery.idFrom!=null?t(errorsFiltersPurchaseDelivery.idFrom):t('Text_Filter_Code_From')}
                               placeholder={t('PlaceHolder_Filter_Code_From')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditPurchaseDelivery}
                               onChange={handleChangeFilterEditPurchaseDelivery}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                             <TextField
                               id="idTo" 
                               name="idTo"
                               value={filtersPurchaseDelivery.idTo}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersPurchaseDelivery.idTo!=null?t(errorsFiltersPurchaseDelivery.idTo):t('Text_Filter_Code_To')}
                               placeholder={t('PlaceHolder_Filter_Code_to')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditPurchaseDelivery}
                               onChange={handleChangeFilterEditPurchaseDelivery}/>
                           </div>
                           {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master'))   &&
                              <>
                                <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                                  <div className="form-group">
                                    <TextField
                                      id="defaultStoreIdFrom"
                                      name="defaultStoreIdFrom"
                                      value={filtersPurchaseDelivery.defaultStoreIdFrom}
                                      inputProps={{ style: {width: 382}, maxLength: 100 }}
                                      label={errorsFiltersPurchaseDelivery.defaultStoreIdFrom!=null?t(errorsFiltersPurchaseDelivery.defaultStoreIdFrom):t('Text_Filter_Stores_From')}
                                      placeholder={t('PlaceHolder_Filter_Store_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditPurchaseDelivery}
                                      onChange={handleChangeFilterEditPurchaseDelivery}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                      <option key={0} value=""/>
                                      {storesToSelect.map((option) => (
                                          <option key={option.id} value={option.id}>
                                             {option.documentid+" - "+option.tradename}
                                          </option>
                                      ))}
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                        id="defaultStoreIdTo"
                                        name="defaultStoreIdTo"
                                        value={filtersPurchaseDelivery.defaultStoreIdTo}  
                                        inputProps={{ style: {width: 382}, maxLength: 100 }}
                                        label={errorsFiltersPurchaseDelivery.defaultStoreIdTo!=null?t(errorsFiltersPurchaseDelivery.defaultStoreIdTo):t('Text_Filter_Stores_To')}
                                        placeholder={t('PlaceHolder_Filter_Store_to')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditPurchaseDelivery}
                                        onChange={handleChangeFilterEditPurchaseDelivery}
                                        select
                                        SelectProps={{
                                        native: true,
                                        }}>
                                         <option key={0} value=""/>
                                        {storesToSelect.filter(item=>(item.id>=filtersPurchaseDelivery.defaultStoreIdFrom)).map(option => (
                                          <option key={option.id} value={option.id}>
                                              {option.documentid+" - "+option.tradename}
                                          </option>
                                        ))}
                                      </TextField>
                                    </div>
                                </Suspense>
                            </>
                           }   
                           { roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_User_Master'))   &&
                              <>
                                <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                                  <div className="form-group">
                                    <TextField
                                      id="useraccountIdFrom"
                                      name="useraccountIdFrom"
                                      value={filtersPurchaseDelivery.useraccountIdFrom}
                                      inputProps={{ style: {width: 382}, maxLength: 100 }}
                                      label={errorsFiltersPurchaseDelivery.useraccountIdFrom!=null?t(errorsFiltersPurchaseDelivery.useraccountIdFrom):t('Text_Filter_Useraccount_From')}
                                      placeholder={t('PlaceHolder_UserAccount_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditPurchaseDelivery}
                                      onChange={handleChangeFilterEditPurchaseDelivery}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                      <option key={0} value=""/>
                                      {userAccountsToSelect.map((option) => (
                                          <option key={option.id} value={option.username}>
                                            {option.username+" - "+option.email}
                                          </option>
                                      ))}
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                        id="useraccountIdTo"
                                        name="useraccountIdTo"
                                        value={filtersPurchaseDelivery.useraccountIdTo}  
                                        inputProps={{ style: {width: 382}, maxLength: 100 }}
                                        label={errorsFiltersPurchaseDelivery.useraccountIdTo!=null?t(errorsFiltersPurchaseDelivery.useraccountIdTo):t('Text_Filter_Useraccount_To')}
                                        placeholder={t('PlaceHolder_UserAccount_To')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditPurchaseDelivery}
                                        onChange={handleChangeFilterEditPurchaseDelivery}
                                        select
                                        SelectProps={{
                                        native: true,
                                        }}>
                                         <option key={0} value=""/>
                                        {userAccountsToSelect.filter(item=>(item.username>=filtersPurchaseDelivery.useraccountIdFrom)).map(option => (
                                          <option key={option.id} value={option.username}>
                                              {option.username+" - "+option.email}
                                          </option>
                                        ))}
                                      </TextField>
                                    </div>
                                </Suspense>
                            </>
                           }   
                           <div className="form-group">
                             <TextField
                               id="purchasenoteFrom" 
                               name="purchasenoteFrom"
                               value={filtersPurchaseDelivery.purchasenoteFrom}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersPurchaseDelivery.purchasenoteFrom!=null?t(errorsFiltersPurchaseDelivery.purchasenoteFrom):t('Text_Filter_NumberPurchase_From')}
                               placeholder={t('PlaceHolder_Filter_NumberPurchase_From')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditPurchaseDelivery}
                               onChange={handleChangeFilterEditPurchaseDelivery}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                             <TextField
                               id="purchasenoteTo" 
                               name="purchasenoteTo"
                               value={filtersPurchaseDelivery.purchasenoteTo}
                               inputProps={{ style: {width: 400}, maxLength: 15 }}
                               label={errorsFiltersPurchaseDelivery.purchasenoteTo!=null?t(errorsFiltersPurchaseDelivery.purchasenoteTo):t('Text_Filter_NumberPurchase_To')}
                               placeholder={t('PlaceHolder_Filter_NumberPurchase_To')}
                               variant="outlined"
                               onBlur={handleBlurFilterEditPurchaseDelivery}
                               onChange={handleChangeFilterEditPurchaseDelivery}/>
                           </div>
                         
                          <div className="form-group">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                  id="datepurchaseFrom" 
                                  name="datepurchaseFrom"
                                  value={ filtersPurchaseDelivery.datepurchaseFrom!== null ? new Date(filtersPurchaseDelivery.datepurchaseFrom): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersPurchaseDelivery.datepurchaseFrom!=null?t(errorsFiltersPurchaseDelivery.datepurchaseFrom):t('Text_Filter_DatePurchase_From')}
                                  onBlur={handleBlurSelectDatePurchaseDeliveryFrom}
                                  onChange={handleChangeSelectDatePurchaseDelivery}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430
                                      }
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                  id="datepurchaseTo" 
                                  name="datepurchaseTo"
                                  minDate={filtersPurchaseDelivery.datepurchaseFrom!== null ? new Date(filtersPurchaseDelivery.datepurchaseFrom): null}
                                  value={ filtersPurchaseDelivery.datepurchaseTo!== null ? new Date(filtersPurchaseDelivery.datepurchaseTo): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersPurchaseDelivery.datepurchaseTo!=null?t(errorsFiltersPurchaseDelivery.datepurchaseTo):t('Text_Filter_DatePurchase_To')}
                                  onBlur={handleBlurSelectDatePurchaseDeliveryTo}
                                  onChange={handleChangeSelectDatePurchaseDeliveryTo}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430
                                      
                                      }
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                          </div>         
                          <div className="form-group">
                            <TextField
                              id="vehiclestore_licenseplateFrom" 
                              name="vehiclestore_licenseplateFrom"
                              value={filtersPurchaseDelivery.vehiclestore_licenseplateFrom}
                              inputProps={{ style: {width: 400}, maxLength: 20 }}
                              label={errorsFiltersPurchaseDelivery.vehiclestore_licenseplateFrom!=null?t(errorsFiltersPurchaseDelivery.vehiclestore_licenseplateFrom):t('Text_Filter_LicensePlate_From')}
                              placeholder={t('PlaceHolder_Filter_LicensePlate_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditPurchaseDelivery}
                              onChange={handleChangeFilterEditPurchaseDelivery}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                            <TextField
                              id="vehiclestore_licenseplateTo" 
                              name="vehiclestore_licenseplateTo"
                              value={filtersPurchaseDelivery.vehiclestore_licenseplateTo}
                              inputProps={{ style: {width: 400}, maxLength: 20 }}
                              label={errorsFiltersPurchaseDelivery.vehiclestore_licenseplateTo!=null?t(errorsFiltersPurchaseDelivery.vehiclestore_licenseplateTo):t('Text_Filter_LicensePlate_To')}
                              placeholder={t('PlaceHolder_Filter_LicensePlate_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditPurchaseDelivery}
                              onChange={handleChangeFilterEditPurchaseDelivery}
                            />
                          </div>
                          <div className="form-group">
                              <TextField
                                id="numberDIFROM" 
                                name="numberDIFROM"
                                value={filtersPurchaseDelivery.numberDIFROM}
                                inputProps={{ style: {width: 400}, maxLength: 20 }}
                                label={errorsFiltersPurchaseDelivery.numberDIFROM!=null?t(errorsFiltersPurchaseDelivery.numberDIFROM):t('Text_Filter_FormDI_From')}
                                placeholder={t('PlaceHolder_Filter_FormDI_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseDelivery}
                                onChange={handleChangeFilterEditPurchaseDelivery}
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <TextField
                                id="numberDITO" 
                                name="numberDITO"
                                value={filtersPurchaseDelivery.numberDITO}
                                inputProps={{ style: {width: 400}, maxLength: 20 }}
                                label={errorsFiltersPurchaseDelivery.numberDITO!=null?t(errorsFiltersPurchaseDelivery.numberDITO):t('Text_Filter_FormDI_To')}
                                placeholder={t('PlaceHolder_Filter_FormDI_To')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseDelivery}
                                onChange={handleChangeFilterEditPurchaseDelivery}
                              />
                            </div>

                          <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                            <div className="form-group">
                              <TextField
                                id="rel_trans_store_idFrom"
                                name="rel_trans_store_idFrom"
                                value={filtersPurchaseDelivery.rel_trans_store_idFrom}
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={errorsFiltersPurchaseDelivery.rel_trans_store_idFrom!=null?t(errorsFiltersPurchaseDelivery.rel_trans_store_idFrom):t('Text_Filter_Trans_From')}
                                placeholder={t('PlaceHolder_Filter_Trans_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseDelivery}
                                onChange={handleChangeFilterEditPurchaseDelivery}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                <option key={0} value=""/>
                                {trasnporterToSelect.map((option) => (
                                    <option key={option.id} value={option.tradename}>
                                        {option.documentid+" - "+option.tradename}
                                    </option>
                                ))}
                              </TextField>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <TextField
                                id="rel_trans_store_idTo"
                                name="rel_trans_store_idTo"
                                value={filtersPurchaseDelivery.rel_trans_store_idTo}  
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={errorsFiltersPurchaseDelivery.rel_trans_store_idTo!=null?t(errorsFiltersPurchaseDelivery.rel_trans_store_idTo):t('Text_Filter_Trans_To')}
                                placeholder={t('PlaceHolder_Filter_Trans_To')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseDelivery}
                                onChange={handleChangeFilterEditPurchaseDelivery}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                  <option key={0} value=""/>
                                {trasnporterToSelect.filter(item=>(item.tradename>=filtersPurchaseDelivery.rel_trans_store_idFrom)).map(option => (
                                  <option key={option.id} value={option.tradename}>
                                      {option.documentid+" - "+option.tradename}
                                  </option>
                                ))}
                              </TextField>
                            </div>
                            <div className="form-group">
                              <TextField
                                id="ori_tras_store_idFrom"
                                name="ori_tras_store_idFrom"
                                value={filtersPurchaseDelivery.ori_tras_store_idFrom}
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={errorsFiltersPurchaseDelivery.ori_tras_store_idFrom!=null?t(errorsFiltersPurchaseDelivery.ori_tras_store_idFrom):t('Text_Filter_TradeName_From')}
                                placeholder={t('PlaceHolder_Filter_TradeName_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseDelivery}
                                onChange={handleChangeFilterEditPurchaseDelivery}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                <option key={0} value=""/>
                                {tradenameToSelect.map((option) => (
                                    <option key={option.id} value={option.tradename}>
                                        {option.documentid+" - "+option.tradename}
                                    </option>
                                ))}
                              </TextField>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <TextField
                                id="ori_tras_store_idTo"
                                name="ori_tras_store_idTo"
                                value={filtersPurchaseDelivery.ori_tras_store_idTo}  
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={errorsFiltersPurchaseDelivery.ori_tras_store_idTo!=null?t(errorsFiltersPurchaseDelivery.ori_tras_store_idTo):t('Text_Filter_TradeName_To')}
                                placeholder={t('PlaceHolder_Filter_TradeName_To')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseDelivery}
                                onChange={handleChangeFilterEditPurchaseDelivery}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                  <option key={0} value=""/>
                                {tradenameToSelect.filter(item=>(item.tradename>=filtersPurchaseDelivery.ori_tras_store_idFrom)).map(option => (
                                  <option key={option.id} value={option.tradename}>
                                      {option.documentid+" - "+option.tradename}
                                  </option>
                                ))}
                              </TextField>
                            </div>

                            <div className="form-group">
                              <TextField
                                id={i18n.language==="es"?"paymentFromES":"paymentFromEN"}
                                name={i18n.language==="es"?"paymentFromES":"paymentFromEN"}
                                value={i18n.language==="es"?filtersPurchaseDelivery.paymentFromES:filtersPurchaseDelivery.paymentFromEN}
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={i18n.language==="es"?errorsFiltersPurchaseDelivery.paymentFromES!=null?t(errorsFiltersPurchaseDelivery.paymentFromES):t('Text_Filter_payment_From'):errorsFiltersPurchaseDelivery.paymentFromEN!=null?t(errorsFiltersPurchaseDelivery.paymentFromEN):t('Text_Filter_payment_From')}
                                placeholder={t('PlaceHolder_Filter_payment_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseDelivery}
                                onChange={handleChangeFilterEditPurchaseDelivery}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                <option key={0} value=""/>
                                {paymenetTypeSelect.map((option) => (
                                <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                                ))}
                              </TextField>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <TextField
                                id={i18n.language==="es"?"paymentToES":"paymentToEN"}
                                name={i18n.language==="es"?"paymentToES":"paymentToEN"}
                                value={i18n.language==="es"?filtersPurchaseDelivery.paymentToES:filtersPurchaseDelivery.paymentToEN}
                                inputProps={{ style: {width: 382}, maxLength: 100 }}
                                label={i18n.language==="es"?errorsFiltersPurchaseDelivery.paymentToES!=null?t(errorsFiltersPurchaseDelivery.paymentToES):t('Text_Filter_payment_To'):errorsFiltersPurchaseDelivery.paymentToEN!=null?t(errorsFiltersPurchaseDelivery.paymentToEN):t('Text_Filter_payment_To')}
                                placeholder={t('PlaceHolder_Filter_payment_To')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditPurchaseDelivery}
                                onChange={handleChangeFilterEditPurchaseDelivery}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                <option key={0} value=""/>
                                {paymenetTypeSelect.filter(item=>(i18n.language==="es"?item.namees>=filtersPurchaseDelivery.paymentFromES:item.nameen>=filtersPurchaseDelivery.paymentFromEN)).map(option => (
                                <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                                ))}
                                </TextField>
                            </div>

                            </Suspense>
                        </div>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEditPurchaseDelivery}>{t('Button_Apply')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFiltersPurchaseDelivery}>{t('Button_Cancel_Filters')}</button>
                        </div>
                     </form>
                   </Box>
                 </Container>
               </SwipeableDrawer>
              </div>
              }  
              <PurchaseDeliveryPDF 
                rowEditPurchaseDelivery={rowEditPurchaseDelivery} 
                viewPDF={viewPDF} 
                setViewPDF={setViewPDF}
              />
              <Dialog open={openDeleteRowPurchaseDelivery} onClose={handleCancelDeleteRowPurchaseDelivery}>
                <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEditPurchaseDelivery.id}  - {rowEditPurchaseDelivery.purchasenote} - {rowEditPurchaseDelivery.ori_tras_tradename} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Text_Line1')}<br/>
                      {t('Text_Line2')}
                    </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCodeDelete}
                        onChange={handleChangeDeleteRowPurchaseDelivery}/>
                    </DialogContent>
                  <DialogActions>
                  <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowPurchaseDelivery}>{t('Button_Cancel')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowPurchaseDelivery}>{t('Button_Delete')}</Button>
                </DialogActions>
              </Dialog> 

              <Dialog open={openCreateBlankPurchaseDelivery} onClose={handleCancelCreateBlankPurchaseDelivery}>
                <DialogTitle style={{color:"#e74a3b"}}>{t('Button_Create_BLANK')} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Text_Line5')}<br/>
                      {t('Text_Line6')}
                    </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label_Create')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCreatePurchase}
                        onChange={handleChangeCreateBlankPurchaseDelivery}/>
                    </DialogContent>
                  <DialogActions>
                  <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelCreateBlankPurchaseDelivery}>{t('Button_Cancel')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleSubmitCreateBlankPurchaseDelivery}>{t('Button_Create')}</Button>
                </DialogActions>
              </Dialog> 

              {/* End Content Row */}
          </>:
            <Loading isLoadingData={isLoadingPurchaseDelivery}/>
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

PurchaseDeliveryFilter.propTypes = {
  applyFiltersPurchaseDelivery: PropTypes.object.isRequired,
  setApplyFiltersPurchaseDelivery: PropTypes.func.isRequired,
  showModalPurchasesDeliverySettings: PropTypes.bool.isRequired,
  setShowModalPurchasesDeliverySettings: PropTypes.func.isRequired,
  typeOfList: PropTypes.string.isRequired
};

export default PurchaseDeliveryFilter;

