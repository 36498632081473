import React, {  useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next" 
import IconButton from '@mui/material/IconButton'; 
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import FormSearchStores from "pages/forms/editOrNewFormDI/FormSearchStores";
import FormSearchStoresAddresses from "./FormSearchStoresAddress";
import FormSearchStoreVehicles from "./FormSearchVehicles"
import PropTypes from 'prop-types';

const initialProvincesToSelect = [{
    "id" : "",
    "name" : "",
    "enabled" : true,
    "country_id":1
}];

const initialPostalCodesToSelect = [{
    "id":"",
    "postalcode":"",
    "name":"",
    "postalcodename":"",
    "enabled":true,
    "province_id":""
}];

const FormCreateOrEditOptTras = ({formFrom,setFormForm,showModalFormSettings,setIsLoadingErrorData,errorsForm,setErrorsForm,validateFormForm,documentTypesToSelect,roadTypesToSelect,communityToSelect,countryToSelect,expanded,optTypeToSelect}) => {
    const {t,i18n} = useTranslation(['listDataForms']);
    const token = useSelector((state) => state.loginUser.token); 
    const roles = useSelector((state) => state.loginUser.roles);  
    const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
    const DEFAULTTYPEOFDOCUMENT = useSelector((state) => state.variablesUser.DEFAULTTYPEOFDOCUMENT); 
    const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
    const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
    const DEFAULTCOMMUNITY = useSelector((state) => state.formatDIUser.default_community); 
    const opt_tras_view_name = useSelector((state) => state.formatDIUser.opt_tras_view_name); 
    const opt_tras_view_contactname = useSelector((state) => state.formatDIUser.opt_tras_view_contactname); 
    const opt_tras_view_address = useSelector((state) => state.formatDIUser.opt_tras_view_address); 
    const opt_tras_view_country = useSelector((state) => state.formatDIUser.opt_tras_view_country); 
    const opt_tras_view_community = useSelector((state) => state.formatDIUser.opt_tras_view_community); 
    const opt_tras_view_phone = useSelector((state) => state.formatDIUser.opt_tras_view_phone); 
    const opt_tras_view_email = useSelector((state) => state.formatDIUser.opt_tras_view_email); 
    const opt_tras_view_nima = useSelector((state) => state.formatDIUser.opt_tras_view_nima); 
    const opt_tras_view_cnae = useSelector((state) => state.formatDIUser.opt_tras_view_cnae); 
    const opt_tras_view_inscription = useSelector((state) => state.formatDIUser.opt_tras_view_inscription); 
    const opt_tras_view_opt = useSelector((state) => state.formatDIUser.opt_tras_view_opt); 
    const opt_tras_view_licenseplate = useSelector((state) => state.formatDIUser.opt_tras_view_licenseplate); 
    const opt_tras_view_economicactivity = useSelector((state) => state.formatDIUser.opt_tras_view_economicactivity); 

    const opt_tras_handle_documnettype= useSelector((state) => state.formatDIUser.opt_tras_handle_documnettype); 
    const opt_tras_handle_documentid= useSelector((state) => state.formatDIUser.opt_tras_handle_documentid); 
    const opt_tras_handle_tradename= useSelector((state) => state.formatDIUser.opt_tras_handle_tradename); 
    const opt_tras_handle_name= useSelector((state) => state.formatDIUser.opt_tras_handle_name); 
    const opt_tras_handle_contactname= useSelector((state) => state.formatDIUser.opt_tras_handle_contactname); 
    const opt_tras_handle_address= useSelector((state) => state.formatDIUser.opt_tras_handle_address); 
    const opt_tras_handle_phone= useSelector((state) => state.formatDIUser.opt_tras_handle_phone); 
    const opt_tras_handle_email= useSelector((state) => state.formatDIUser.opt_tras_handle_email); 
    const opt_tras_handle_nima= useSelector((state) => state.formatDIUser.opt_tras_handle_nima); 
    const opt_tras_handle_cnae= useSelector((state) => state.formatDIUser.opt_tras_handle_cnae); 
    const opt_tras_handle_inscription= useSelector((state) => state.formatDIUser.opt_tras_handle_inscription); 
    const opt_tras_handle_opt= useSelector((state) => state.formatDIUser.opt_tras_handle_opt); 
    const opt_tras_handle_licenseplate= useSelector((state) => state.formatDIUser.opt_tras_handle_licenseplate); 
    const opt_tras_handle_economicactivity= useSelector((state) => state.formatDIUser.opt_tras_handle_economicactivity); 
    
    const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
    const [showModalSearchStoresAddress, setShowModalSearchStoresAddress] = useState(false); 
    const [showModalSearchVehicles, setShowModalSearchVehicles] = useState(false); 

    const [provinceToSelect_Opt_Tras,setProvinceToSelect_Opt_Tras]= useState(initialProvincesToSelect);
    const [postalCodeToSelect_Opt_Tras,setPostalCodeToSelect_Opt_Tras]= useState(initialPostalCodesToSelect);
    const dispatch = useDispatch(); 

    const handleChangeSelectDocumentType_Opt_Tras = (event) => {
        setFormForm(formFrom => ({
            ...formFrom,
            opt_tras_documnettype_id:event.target.value
        }));
    };
    const handleBlurSelectDocumentType_Opt_Tras = (e) => {
        handleChangeSelectDocumentType_Opt_Tras(e);
    };
    
    const handleChangeSelectRoadType_Opt_Tras = (event) => {
        setFormForm(formFrom => ({
            ...formFrom,
            opt_tras_roadtype_id : event.target.value 
        }));
    };
    const handleBlurSelectRoadType_Opt_Tras = (e) => {
        handleChangeSelectRoadType_Opt_Tras(e);
        setErrorsForm(validateFormForm());
    };
    
    const handleChangeSelectCountry_Opt_Tras = (event) => {
        setFormForm(formFrom => ({
            ...formFrom,
            opt_tras_country_id: event.target.value,
            opt_tras_province_id:Number(DEFAULTPROVINCE),
            opt_tras_postalcode_id:""
        })); 
    };
    const handleBlurSelectCountry_Opt_Tras = (e) => {
        handleChangeSelectCountry_Opt_Tras(e);
        setErrorsForm(validateFormForm());
    };
    
    const handleChangeSelectProvince_Opt_Tras = (event) => {
        setFormForm(formFrom => ({
            ...formFrom,
            opt_tras_province_id :event.target.value,
            opt_tras_postalcode_id:""
        }));
    };
    const handleBlurSelectProvince_Opt_Tras = (e) => {
        handleChangeSelectProvince_Opt_Tras(e);
        setErrorsForm(validateFormForm());
    };
    
    const handleChangeSelectPostalCode_Opt_Tras = (event) => {
        setFormForm(formFrom => ({
            ...formFrom,
            opt_tras_postalcode_id:event.target.value
        }));
    };
    const handleBlurSelectPostalCode_Opt_Tras = (e) => {
        handleChangeSelectPostalCode_Opt_Tras(e);
        setErrorsForm(validateFormForm());
    };

    const handleChangeSelectCommunity_Opt_Tras = (event) => {
        setFormForm(formFrom => ({
            ...formFrom,
            opt_tras_community_id : event.target.value   
        }));
    };
    const handleBlurSelectCommunity_Opt_Tras = (e) => {
        handleChangeSelectCommunity_Opt_Tras(e);
        setErrorsForm(validateFormForm());
    };

    const handleChangeDataEditForm_Opt_Tras = (e) => {
        const { name, value } = e.target;
        setFormForm(formFrom => ({
          ...formFrom,
          [name]: value,
        }));
    };
    const handleBlurDataEditForm_Opt_Tras = (e) => {
        handleChangeDataEditForm_Opt_Tras(e);
        setErrorsForm(validateFormForm());
    };
    
    const searchFormStore = (event) => {
        setShowModalSearchStores(true);
    };

    const searchFormStoreAddress = (event) => {
        setShowModalSearchStoresAddress(true);
    };

    const searchFormVehicles = (event) => {
        setShowModalSearchVehicles(true);
    };

    const deleteDateOptTras = (event) => {
        setFormForm(formFrom => ({
          ...formFrom,
            opt_tras_store_id:0,
            opt_tras_documnettype_id: "",
            opt_tras_documentid:"",
            opt_tras_tradename: "",
            opt_tras_name: "",
            opt_tras_contactname: "",
            opt_tras_roadtype_id: 0,
            opt_tras_street: "",
            opt_tras_country_id: Number(DEFAULTCOUNTRY),
            opt_tras_province_id: Number(DEFAULTPROVINCE),
            opt_tras_postalcode_id: "",
            opt_tras_community_id: Number(DEFAULTCOMMUNITY),
            opt_tras_phone: "",
            opt_tras_email: "",
            opt_tras_nima: "",
            opt_tras_cnae: "",
            opt_tras_inscription: "",
            opt_tras_opt: 0,
            opt_tras_licenseplate: "",
            opt_tras_economicactivity:""
        }));
        setErrorsForm(validateFormForm());
        setErrorsForm(errorsForm => ({
            ...errorsForm,
            opt_tras_store_id:0,
            opt_tras_documnettype_id: "",
            opt_tras_documentid:"",
            opt_tras_tradename: "",
            opt_tras_name: "",
            opt_tras_contactname: "",
            opt_tras_roadtype_id: "",
            opt_tras_street: "",
            opt_tras_country_id: "",
            opt_tras_province_id: "",
            opt_tras_postalcode_id: "",
            opt_tras_community_id: "",
            opt_tras_phone: "",
            opt_tras_email: "",
            opt_tras_nima: "",
            opt_tras_cnae: "",
            opt_tras_inscription: "",
            opt_tras_opt: "",
            opt_tras_licenseplate: "",
            opt_tras_economicactivity:""
        }));
    };

    const deleteDataSteetOptTras = (event) => {
        setFormForm(formFrom => ({
          ...formFrom,
            opt_tras_roadtype_id: 0,
            opt_tras_street: "",
            opt_tras_country_id: Number(DEFAULTCOUNTRY),
            opt_tras_province_id: Number(DEFAULTPROVINCE),
            opt_tras_postalcode_id: "",
            opt_tras_community_id: Number(DEFAULTCOMMUNITY),
            opt_tras_nima: "", 
            opt_tras_inscription: "",
            opt_tras_opt: 0,
        }));
        setErrorsForm(validateFormForm());
    };

    const deleteDataVehicleOptTras = (event) => {
        setFormForm(formFrom => ({
          ...formFrom,
          opt_tras_licenseplate: ""
        }));
        setErrorsForm(validateFormForm());
    };

    useEffect(() => {
        let isMounted = true;
        const fetchExistNumberDocument = async () => {
            try {
              const getData = await helpHttp().get2(ENDPOINT,`form/getexiststorebynumberdocument/`,token,formFrom.opt_tras_documentid);
              if (getData.err) {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              } else if (isMounted) {
                if (getData>0 && getData!==formFrom.opt_tras_store_id){
                    fetchStoreNumberDocument();
                }
              }
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
 
        const fetchStoreNumberDocument = async () => {
            try {
                const getData = await helpHttp().get5(ENDPOINT,`form/getformstorebynumberdocument`,token,formFrom.opt_tras_documentid,1);
                if (getData.err) {
                    dispatch(warningErrorAction(t('Text_InvalidCredentials')));
                } else if (isMounted) {
                    if (getData!==undefined && getData!==null && getData!==""){
                        setFormForm(formFrom => ({
                            ...formFrom,
                            opt_tras_store_id: getData.store_id,
                            opt_tras_documnettype_id: getData.documnettype_id,
                            opt_tras_tradename: getData.tradename,
                            opt_tras_name: getData.name,
                            opt_tras_contactname: getData.contacperson,
                            opt_tras_roadtype_id: (getData.roadtype_id===undefined 
                                                    || getData.roadtype_id===null  
                                                    || getData.roadtype_id===""
                                                    || getData.roadtype_id==="0"
                                                    || getData.roadtype_id===0)?
                                                    0:getData.roadtype_id,
                            opt_tras_street: getData.street,
                            opt_tras_country_id: (getData.country_id===undefined 
                                                    || getData.country_id===null  
                                                    || getData.country_id===""
                                                    || getData.country_id==="0"
                                                    || getData.country_id===0)?
                                                    Number(DEFAULTCOUNTRY):getData.country_id,
                            opt_tras_province_id: (getData.province_id===undefined 
                                                    || getData.province_id===null  
                                                    || getData.province_id===""
                                                    || getData.province_id==="0"
                                                    || getData.province_id===0)?
                                                    Number(DEFAULTPROVINCE):getData.province_id,
                            opt_tras_postalcode_id: (getData.postalcode_id===undefined 
                                                        || getData.postalcode_id===null  
                                                        || getData.postalcode_id===""
                                                        || getData.postalcode_id==="0"
                                                        || getData.postalcode_id===0)?
                                                        "":getData.postalcode_id,
                            opt_tras_community_id: (getData.community_id===undefined 
                                                        || getData.community_id===null  
                                                        || getData.community_id===""
                                                        || getData.community_id==="0"
                                                        || getData.community_id===0)?
                                                        Number(DEFAULTCOMMUNITY):getData.community_id,
                            opt_tras_phone: getData.phone,
                            opt_tras_email: getData.email,
                            opt_tras_nima: getData.nima_opt_tras,
                            opt_tras_cnae: getData.cnae,
                            opt_tras_inscription: getData.pygr_opt_tras,
                            opt_tras_opt: getData.opt_opt_tras,
                            opt_tras_licenseplate: getData.licenseplate,
                            opt_tras_economicactivity:getData.economicactivity
                        }));
                    }  
                }         
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel1'){
            if (formFrom.opt_tras_documentid!==""){
                fetchExistNumberDocument();
            }
        }
        return () => { isMounted = false };
    }, [ENDPOINT,t,token,dispatch,expanded,formFrom.opt_tras_documentid,errorsForm.opt_tras_documentid,setIsLoadingErrorData,DEFAULTCOMMUNITY,DEFAULTCOUNTRY,DEFAULTPROVINCE,setFormForm,formFrom.opt_tras_store_id]);
    
    useEffect(() => {
        let isMounted = true;
        const fetchDataProvince = async () => {
          try {
            const getData = formFrom.opt_tras_country_id!==""
                ? await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,formFrom.opt_tras_country_id)
                : await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,"null");
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setProvinceToSelect_Opt_Tras(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel1'){
            fetchDataProvince();
        }      
        return () => { isMounted = false };     
    }, [ENDPOINT,t,token,dispatch,i18n,showModalFormSettings,formFrom.opt_tras_country_id,expanded]);
   
    useEffect(() => {
        let isMounted = true;
        const fetchDataPostalCode = async () => {
          try {
            const getData = formFrom.opt_tras_province_id!==""
                ? await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbyprovincebyname/`,token,formFrom.opt_tras_province_id)
                : await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbycountry/`,token,formFrom.opt_tras_country_id);
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setPostalCodeToSelect_Opt_Tras(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel1'){
            fetchDataPostalCode();
        }      
        return () => { isMounted = false };  
       
    }, [ENDPOINT,t,token,dispatch,i18n,showModalFormSettings,formFrom.opt_tras_country_id,formFrom.opt_tras_province_id,expanded]);
 
    return(
    (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel1') &&
        <>
            <div className="row p-2"> 
                <div className="form-group"> 
                <label className="text-primary">{t('Text_FORM_DocuentType')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <TextField
                    id="opt_tras_documnettype_id"
                    name="opt_tras_documnettype_id"
                    value={ (formFrom.opt_tras_documnettype_id === undefined || formFrom.opt_tras_documnettype_id ===null || formFrom.opt_tras_documnettype_id ===""
                        || formFrom.opt_tras_documnettype_id ==="0" || formFrom.opt_tras_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT):formFrom.opt_tras_documnettype_id}
                    inputProps={{ style: {width: 100}, maxLength: 100 }}
                    variant="outlined"
                    onBlur={handleBlurSelectDocumentType_Opt_Tras}
                    onChange={handleChangeSelectDocumentType_Opt_Tras}
                    disabled={!opt_tras_handle_documnettype}
                    helperText={errorsForm.opt_tras_documnettype_id!=null && errorsForm.opt_tras_documnettype_id!==""?t(errorsForm.opt_tras_documnettype_id):""}
                    select
                    SelectProps={{
                        native: true,
                        value: (formFrom.opt_tras_documnettype_id === undefined || formFrom.opt_tras_documnettype_id ===null || formFrom.opt_tras_documnettype_id ===""
                            || formFrom.opt_tras_documnettype_id ==="0" || formFrom.opt_tras_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT): formFrom.opt_tras_documnettype_id
                    }}>
                    <option key={0} value=""/>
                    {documentTypesToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </TextField>  
                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                <label className="text-primary">{t('Text_FORM_CIF')} :</label>
            
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <TextField
                    id="opt_tras_documentid" 
                    name="opt_tras_documentid"
                    value={formFrom.opt_tras_documentid}
                    inputProps={{ style: {width: i18n.language==='es'?265:301}, maxLength: 100 }}
                    label={errorsForm.opt_tras_documentid!=null && errorsForm.opt_tras_documentid!==""?t(errorsForm.opt_tras_documentid):t('PlaceHolder_FORM_CIF')}
                    placeholder={t('PlaceHolder_FORM_CIF')}
                    variant="standard"
                    onBlur={handleBlurDataEditForm_Opt_Tras}
                    onChange={handleChangeDataEditForm_Opt_Tras}
                    disabled={!opt_tras_handle_documentid}
                />
                &nbsp;&nbsp;
                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDateOptTras}>
                    <DeleteIcon/>
                </IconButton>
                &nbsp;&nbsp;
                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                    <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchFormStore}>
                        <SearchIcon/>
                    </IconButton>
                }
                </div>
            </div>
            <div className="row p-2"> 
                <div className="form-group"> 
                <label className="text-primary">{t('Text_FORM_TRADENAME')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <TextField
                    id="opt_tras_tradename" 
                    name="opt_tras_tradename"
                    value={formFrom.opt_tras_tradename}
                    inputProps={{ style: {width: i18n.language==='es'?710:689}, maxLength: 100 }}
                    label={errorsForm.opt_tras_tradename!=null && errorsForm.opt_tras_tradename!==""?t(errorsForm.opt_tras_tradename):t('PlaceHolder_FORM_TRADENAME')}
                    placeholder={t('PlaceHolder_FORM_TRADENAME')}
                    variant="standard"
                    onBlur={handleBlurDataEditForm_Opt_Tras}
                    onChange={handleChangeDataEditForm_Opt_Tras}
                    disabled={!opt_tras_handle_tradename}
                />
                </div>
            </div>
            {opt_tras_view_name &&
                <div className="row p-2"> 
                    <div className="form-group"> 
                    <label className="text-primary">{t('Text_FORM_NAME')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="opt_tras_name" 
                        name="opt_tras_name"
                        value={formFrom.opt_tras_name}
                        inputProps={{ style: {width: i18n.language==='es'?670:710}, maxLength: 100 }}
                        label={errorsForm.opt_tras_name!=null && errorsForm.opt_tras_name!==""?t(errorsForm.opt_tras_name):t('PlaceHolder_FORM_NAME')}
                        placeholder={t('PlaceHolder_FORM_NAME')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Opt_Tras}
                        onChange={handleChangeDataEditForm_Opt_Tras}
                        disabled={!opt_tras_handle_name}
                    />
                    </div>
                </div> 
            }
            {opt_tras_view_contactname &&
                <div className="row p-2"> 
                    <div className="form-group"> 
                    <label className="text-primary">{t('Text_FORM_CONTACT')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="opt_tras_contactname" 
                        name="opt_tras_contactname"
                        value={formFrom.opt_tras_contactname}
                        inputProps={{ style: {width: i18n.language==='es'?655:695}, maxLength: 100 }}
                        label={errorsForm.opt_tras_contactname!=null && errorsForm.opt_tras_contactname!==""?t(errorsForm.opt_tras_contactname):t('PlaceHolder_FORM_CONTACT')}
                        placeholder={t('PlaceHolder_FORM_CONTACT')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Opt_Tras}
                        onChange={handleChangeDataEditForm_Opt_Tras}
                        disabled={!opt_tras_handle_contactname}
                    />
                    </div>
                </div> 
            }
            {opt_tras_view_address &&
                <>
                    <div className="row p-2"> 
                        <div className="form-group">
                        <label className="text-primary">{t('Text_FORM_RoadType')} :</label>
                        &nbsp;&nbsp;&nbsp;&nbsp; 
                        <TextField
                            id="opt_tras_roadtype"
                            name="opt_tras_roadtype"
                            value={ (formFrom.opt_tras_roadtype_id === undefined || formFrom.opt_tras_roadtype_id ===null || formFrom.opt_tras_roadtype_id ===""
                                || formFrom.opt_tras_roadtype_id ==="0" || formFrom.opt_tras_roadtype_id ===0)?"":formFrom.opt_tras_roadtype_id}
                            inputProps={{ style: {width: 100}, maxLength: 100, }}
                            variant="outlined"
                            onBlur={handleBlurSelectRoadType_Opt_Tras}
                            onChange={handleChangeSelectRoadType_Opt_Tras}
                            disabled={!opt_tras_handle_address}
                            helperText={errorsForm.opt_tras_roadtype_id!=null && errorsForm.opt_tras_roadtype_id!==""?t(errorsForm.opt_tras_roadtype_id):""}
                            select
                            SelectProps={{
                                native: true,
                                value: (formFrom.opt_tras_roadtype_id === undefined || formFrom.opt_tras_roadtype_id ===null || formFrom.opt_tras_roadtype_id ===""
                                    || formFrom.opt_tras_roadtype_id ==="0" || formFrom.opt_tras_roadtype_id ===0)?"": formFrom.opt_tras_roadtype_id
                            }}>
                            <option key={0} value=""/>
                            {roadTypesToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                            ))}
                        </TextField>  
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <label className="text-primary">{t('Text_FORM_Address')} :</label>
                        &nbsp;&nbsp;&nbsp;&nbsp; 
                        <TextField
                            id="opt_tras_street" 
                            name="opt_tras_street"
                            value={formFrom.opt_tras_street}
                            inputProps={{ style: {width: i18n.language==="es"? 375:387}, maxLength: 250 }}
                            label={errorsForm.opt_tras_street!=null && errorsForm.opt_tras_street!==""?t(errorsForm.opt_tras_street):t('PlaceHolder_FORM_Address')}
                            placeholder={t('PlaceHolder_FORM_Address')}
                            variant="standard"
                            onBlur={handleBlurDataEditForm_Opt_Tras}
                            onChange={handleChangeDataEditForm_Opt_Tras}
                            disabled={!opt_tras_handle_address}
                        />
                        </div>
                        &nbsp;&nbsp;
                        <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataSteetOptTras}>
                            <DeleteIcon/>
                        </IconButton>
                        &nbsp;&nbsp;
                        {(roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores')) && formFrom.opt_tras_documentid!==undefined && formFrom.opt_tras_documentid!=="") &&
                            <IconButton  size="small"  style={{
                                color:
                                (formFrom.opt_tras_store_id===undefined
                                || formFrom.opt_tras_store_id===""
                                || formFrom.opt_tras_store_id==="0"
                                || formFrom.opt_tras_store_id===0)?"#858796":"#4e73df", fontSize: 12}} 
                                disabled={formFrom.opt_tras_store_id===undefined
                                        || formFrom.opt_tras_store_id===""
                                        || formFrom.opt_tras_store_id==="0"
                                        || formFrom.opt_tras_store_id===0 } onClick={searchFormStoreAddress}>
                            <SearchIcon/>
                            </IconButton>
                        }
                    </div>
 
                    {(opt_tras_view_country || opt_tras_view_community) &&
                        <div className="row p-2"> 
                            <div className="form-group">
                                {opt_tras_view_country &&
                                    <>
                                        <label className="text-primary">{t('Text_FORM_Country')} :</label>
                                        &nbsp;&nbsp;&nbsp;&nbsp; 
                                        <TextField
                                            id="opt_tras_country_id"
                                            name="opt_tras_country_id"
                                            value={ (formFrom.opt_tras_country_id === undefined || formFrom.opt_tras_country_id ===null || formFrom.opt_tras_country_id ===""
                                                    || formFrom.opt_tras_country_id ==="0" || formFrom.opt_tras_country_id ===0)?Number(DEFAULTCOUNTRY):formFrom.opt_tras_country_id}
                                            inputProps={{ style: {width: 235}, maxLength: 100 }}
                                            variant="outlined"
                                            onBlur={handleBlurSelectCountry_Opt_Tras}
                                            onChange={handleChangeSelectCountry_Opt_Tras}
                                            disabled={!opt_tras_handle_address}
                                            helperText={errorsForm.opt_tras_country_id!=null && errorsForm.opt_tras_country_id!==""?t(errorsForm.opt_tras_country_id):""}
                                            select
                                            SelectProps={{
                                                native: true,
                                                value: (formFrom.opt_tras_country_id === undefined || formFrom.opt_tras_country_id ===null || formFrom.opt_tras_country_id ===""
                                                    || formFrom.opt_tras_country_id ==="0" || formFrom.opt_tras_country_id ===0)?Number(DEFAULTCOUNTRY): formFrom.opt_tras_country_id
                                            }}>
                                            {countryToSelect.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {i18n.language==="es"?option.namees:option.nameen}
                                            </option>
                                            ))}
                                        </TextField>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </>
                                }
                                {opt_tras_view_community &&
                                <>
                                    <label className="text-primary">{t('Text_FORM_Community')} :</label>
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <TextField
                                        id="opt_tras_community_id"
                                        name="opt_tras_community_id"
                                        value={ (formFrom.opt_tras_community_id === undefined || formFrom.opt_tras_community_id ===null || formFrom.opt_tras_community_id ===""
                                            || formFrom.opt_tras_community_id ==="0" || formFrom.opt_tras_community_id ===0)?Number(DEFAULTCOMMUNITY):formFrom.opt_tras_community_id}
                                        inputProps={{ style: {width: i18n.language==='es'?293:270}, maxLength: 100, }}
                                        variant="outlined"
                                        onBlur={handleBlurSelectCommunity_Opt_Tras}
                                        onChange={handleChangeSelectCommunity_Opt_Tras}
                                        disabled={!opt_tras_handle_address}
                                        helperText={errorsForm.opt_tras_community_id!=null  && errorsForm.opt_tras_community_id!==""?t(errorsForm.opt_tras_community_id):""}
                                        select
                                        SelectProps={{
                                            native: true,
                                            value: (formFrom.opt_tras_community_id === undefined || formFrom.opt_tras_community_id ===null || formFrom.opt_tras_community_id ===""
                                                || formFrom.opt_tras_community_id ==="0" || formFrom.opt_tras_community_id ===0)?Number(DEFAULTCOMMUNITY): formFrom.opt_tras_community_id
                                        }}>
                                        {communityToSelect.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {i18n.language==="es"?option.namees:option.nameen}
                                            </option>
                                        ))}
                                    </TextField>  
                                </>
                            } 
                            </div>
                        </div>
                    }
                    <div className="row p-2"> 
                        <div className="form-group">
                            <label className="text-primary">{t('Text_FORM_Province')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="opt_tras_province_id"
                                name="opt_tras_province_id"
                                value={ (formFrom.opt_tras_province_id === undefined || formFrom.opt_tras_province_id ===null || formFrom.opt_tras_province_id ===""
                                    || formFrom.opt_tras_province_id ==="0" || formFrom.opt_tras_province_id ===0)?Number(DEFAULTPROVINCE):formFrom.opt_tras_province_id}
                                inputProps={{ style: {width: 200}, maxLength: 100 }}
                                variant="outlined"
                                onBlur={handleBlurSelectProvince_Opt_Tras}
                                onChange={handleChangeSelectProvince_Opt_Tras}
                                disabled={!opt_tras_handle_address}
                                helperText={errorsForm.opt_tras_province_id!=null && errorsForm.opt_tras_province_id!==""?t(errorsForm.opt_tras_province_id):""}
                                select
                                SelectProps={{
                                    native: true,
                                    value: (formFrom.opt_tras_province_id === undefined || formFrom.opt_tras_province_id ===null || formFrom.opt_tras_province_id ===""
                                        || formFrom.opt_tras_province_id ==="0" || formFrom.opt_tras_province_id ===0)?Number(DEFAULTPROVINCE): formFrom.opt_tras_province_id
                                }}>
                                <option key={0} value=""/>
                                {provinceToSelect_Opt_Tras.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                                ))}
                            </TextField>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary">{t('Text_FORM_PostalCode')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="opt_tras_postalcode_id"
                                name="opt_tras_postalcode_id"
                                value={ (formFrom.opt_tras_postalcode_id === undefined || formFrom.opt_tras_postalcode_id ===null || formFrom.opt_tras_postalcode_id ===""
                                    || formFrom.opt_tras_postalcode_id ==="0" || formFrom.opt_tras_postalcode_id ===0)?"":formFrom.opt_tras_postalcode_id}
                                inputProps={{ style: {width: i18n.language==='es'?290:306 }, maxLength: 100 }}
                                variant="outlined"
                                onBlur={handleBlurSelectPostalCode_Opt_Tras}
                                onChange={handleChangeSelectPostalCode_Opt_Tras}
                                disabled={!opt_tras_handle_address}
                                helperText={errorsForm.opt_tras_postalcode_id!=null && errorsForm.opt_tras_postalcode_id!==""?t(errorsForm.opt_tras_postalcode_id):""}
                                select
                                SelectProps={{
                                native: true,
                                value: (formFrom.opt_tras_postalcode_id === undefined || formFrom.opt_tras_postalcode_id ===null || formFrom.opt_tras_postalcode_id ===""
                                    || formFrom.opt_tras_postalcode_id ==="0" || formFrom.opt_tras_postalcode_id ===0)?"": formFrom.opt_tras_postalcode_id
                                }}>
                                <option key={0} value=""/>
                                {postalCodeToSelect_Opt_Tras.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.postalcodename}
                                </option>
                                ))}
                            </TextField>
                        </div>
                    </div>
                </>
            }    
            { (opt_tras_view_phone || opt_tras_view_email || opt_tras_view_nima) &&
                <div className="row p-2"> 
                    <div className="form-group">
                        {opt_tras_view_phone &&
                            <>
                                <label className="text-primary">{t('Text_FORM_Phone')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                    id="opt_tras_phone" 
                                    name="opt_tras_phone"
                                    value={formFrom.opt_tras_phone}
                                    inputProps={{ style: {width: 180}, maxLength: 30 }}
                                    label={errorsForm.opt_tras_phone!=null && errorsForm.opt_tras_phone!==""?t(errorsForm.opt_tras_phone):t('PlaceHolder_FORM_Phone')}
                                    placeholder={t('PlaceHolder_FORM_Phone')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditForm_Opt_Tras}
                                    onChange={handleChangeDataEditForm_Opt_Tras}
                                    disabled={!opt_tras_handle_phone}
                                />
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                            </>
                        }
                        {opt_tras_view_email &&
                            <>
                                <label className="text-primary">{t('Text_FORM_Email')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                    id="opt_tras_email" 
                                    name="opt_tras_email"
                                    value={formFrom.opt_tras_email}
                                    inputProps={{ style: {width:  (!opt_tras_view_phone || !opt_tras_view_nima)?(i18n.language==='es'?380:394):(i18n.language==='es'?180:194)}, maxLength: 100 }}
                                    label={errorsForm.opt_tras_email!=null && errorsForm.opt_tras_email!==""?t(errorsForm.opt_tras_email):t('PlaceHolder_FORM_Email')}
                                    placeholder={t('PlaceHolder_FORM_Email')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditForm_Opt_Tras}
                                    onChange={handleChangeDataEditForm_Opt_Tras}
                                    disabled={!opt_tras_handle_email}
                                />
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                            </>
                        }
                        {opt_tras_view_nima &&
                            <>
                                <label className="text-primary">{t('Text_FORM_NIMA')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                    id="opt_tras_nima" 
                                    name="opt_tras_nima"
                                    value={formFrom.opt_tras_nima}
                                    inputProps={{ style: {width: (!opt_tras_view_phone || !opt_tras_view_email)?269:182}, maxLength: 50 }}
                                    label={errorsForm.opt_tras_nima!=null && errorsForm.opt_tras_nima!==""?t(errorsForm.opt_tras_nima):t('PlaceHolder_FORM_NIMA')}
                                    placeholder={t('PlaceHolder_FORM_NIMA')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditForm_Opt_Tras}
                                    onChange={handleChangeDataEditForm_Opt_Tras}
                                    disabled={!opt_tras_handle_nima}
                                />
                            </>
                        }
                    </div>
                </div>  
            }
            { (opt_tras_view_inscription || opt_tras_view_opt) &&
                <div className="row p-2"> 
                    <div className="form-group">
                    {opt_tras_view_inscription &&
                        <>
                            <label className="text-primary">{t('Text_FORM_INSCRIPTION')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="opt_tras_inscription" 
                                name="opt_tras_inscription"
                                value={formFrom.opt_tras_inscription}
                                inputProps={{ style: {width: !opt_tras_view_opt?350:250}, maxLength: 50 }}
                                label={errorsForm.opt_tras_inscription!=null && errorsForm.opt_tras_inscription!==""?t(errorsForm.opt_tras_inscription):t('PlaceHolder_FORM_INSCRIPTION')}
                                placeholder={t('PlaceHolder_FORM_INSCRIPTION')}
                                variant="standard"
                                onBlur={handleBlurDataEditForm_Opt_Tras}
                                onChange={handleChangeDataEditForm_Opt_Tras}
                                disabled={!opt_tras_handle_inscription}
                            />
                            {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                        </>
                    }
                    {opt_tras_view_opt &&
                        <>
                            <label className="text-primary">{t('Text_FORM_OPT')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="opt_tras_opt"
                                name="opt_tras_opt"
                                value={ (formFrom.opt_tras_opt === undefined || formFrom.opt_tras_opt ===null || formFrom.opt_tras_opt ===""
                                    || formFrom.opt_tras_opt ==="0" || formFrom.opt_tras_opt ===0)?0:formFrom.opt_tras_opt}
                                inputProps={{ style: {width: i18n.language==='es'?190:170}, maxLength: 100 }}  
                                variant="outlined"
                                onBlur={handleBlurDataEditForm_Opt_Tras}
                                onChange={handleChangeDataEditForm_Opt_Tras} 
                                helperText={errorsForm.opt_tras_opt!=null?t(errorsForm.opt_tras_opt):""}
                                disabled={!opt_tras_handle_opt}
                                select
                                SelectProps={{
                                    native: true,
                                    value: (formFrom.opt_tras_opt === undefined || formFrom.opt_tras_opt ===null || formFrom.opt_tras_opt ===""
                                        || formFrom.opt_tras_opt ==="0" || formFrom.opt_tras_opt ===0)?0: formFrom.opt_tras_opt
                                }}>
                                <option key={0} value=""/>
                                {optTypeToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.code} - {option.namees}
                                </option>
                                ))}
                            </TextField>  
                        </>
                    }
                    </div>
                </div> 
            }


            { (opt_tras_view_cnae || opt_tras_view_licenseplate) &&
                <div className="row p-2"> 
                    <div className="form-group">
                    {opt_tras_view_cnae &&
                        <>
                            <label className="text-primary">{t('Text_FORM_CNAE')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="opt_tras_cnae" 
                                name="opt_tras_cnae"
                                value={formFrom.opt_tras_cnae}
                                inputProps={{ style: {width: !opt_tras_view_licenseplate?355:250}, maxLength: 100 }}
                                label={errorsForm.opt_tras_cnae!=null && errorsForm.opt_tras_cnae!==""?t(errorsForm.opt_tras_cnae):t('PlaceHolder_FORM_CNAE')}
                                placeholder={t('PlaceHolder_FORM_CNAE')}
                                variant="standard"
                                onBlur={handleBlurDataEditForm_Opt_Tras}
                                onChange={handleChangeDataEditForm_Opt_Tras}
                                disabled={!opt_tras_handle_cnae}
                            />
                            {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                        </>
                    }
                    {opt_tras_view_licenseplate &&
                        <>
                            <label className="text-primary">{t('Text_FORM_Vehicles')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="opt_tras_licenseplate" 
                                name="opt_tras_licenseplate"
                                value={formFrom.opt_tras_licenseplate}
                                inputProps={{ style: {width: !opt_tras_view_cnae?320:(i18n.language==='es'?320:330)}, maxLength: 100 }}
                                label={errorsForm.opt_tras_licenseplate!=null && errorsForm.opt_tras_licenseplate!==""?t(errorsForm.opt_tras_licenseplate):t('PlaceHolder_FORM_Vehicles')}
                                placeholder={t('PlaceHolder_FORM_Vehicles')}
                                variant="standard"
                                onBlur={handleBlurDataEditForm_Opt_Tras}
                                onChange={handleChangeDataEditForm_Opt_Tras}
                                disabled={!opt_tras_handle_licenseplate}
                            />
                             &nbsp;&nbsp;
                            <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataVehicleOptTras}>
                                <DeleteIcon/>
                            </IconButton>
                            &nbsp;&nbsp;
                            {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                                <IconButton  size="small"  style={{
                                            color:
                                            (formFrom.opt_tras_store_id===undefined
                                            || formFrom.opt_tras_store_id===""
                                            || formFrom.opt_tras_store_id==="0"
                                            || formFrom.opt_tras_store_id===0)?"#858796":"#4e73df", fontSize: 12}} 
                                            disabled={formFrom.opt_tras_store_id===undefined
                                                    || formFrom.opt_tras_store_id===""
                                                    || formFrom.opt_tras_store_id==="0"
                                                    || formFrom.opt_tras_store_id===0 } onClick={searchFormVehicles}>
                                    <SearchIcon/>
                                </IconButton>
                            }

                        </>
                    }
                    </div>
                </div> 
            }
            {opt_tras_view_economicactivity &&
                <div className="row p-2"> 
                    <div className="form-group"> 
                    <label className="text-primary">{t('Text_FORM_Economicactivity')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="opt_tras_economicactivity" 
                        name="opt_tras_economicactivity"
                        value={formFrom.opt_tras_economicactivity}
                        inputProps={{ style: {width: i18n.language==='es'?660:683}, maxLength: 100 }}
                        label={errorsForm.opt_tras_economicactivity!=null && errorsForm.opt_tras_economicactivity!==""?t(errorsForm.opt_tras_economicactivity):t('PlaceHolder_FORM_Economicactivity')}
                        placeholder={t('PlaceHolder_FORM_Economicactivity')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Opt_Tras}
                        onChange={handleChangeDataEditForm_Opt_Tras}
                        disabled={!opt_tras_handle_economicactivity}
                    />
                    </div>
                </div> 
            }
            <FormSearchStores
                showModalSearchStores={showModalSearchStores}
                setShowModalSearchStores={setShowModalSearchStores}
                selectStores={"Opt_Tras"}
                setFormForm={setFormForm}
            />
            <FormSearchStoresAddresses
                showModalSearchStoresAddress={showModalSearchStoresAddress}
                setShowModalSearchStoresAddress={setShowModalSearchStoresAddress}
                selectStores={"Opt_Tras"}
                setFormForm={setFormForm}
                titleTable={formFrom.opt_tras_tradename}
                store_id={formFrom.opt_tras_store_id}
                priority={1}
            />

            <FormSearchStoreVehicles
                showModalSearchVehicles={showModalSearchVehicles}
                setShowModalSearchVehicles={setShowModalSearchVehicles}
                selectStores={"Opt_Tras"}
                setFormForm={setFormForm}
                titleTable={formFrom.opt_tras_tradename}
                store_id={formFrom.opt_tras_store_id}
            />

        </> 
    )
}

FormCreateOrEditOptTras.propTypes = {
    formFrom: PropTypes.object.isRequired,
    setFormForm: PropTypes.func.isRequired,
    showModalFormSettings: PropTypes.bool.isRequired,
    setIsLoadingErrorData: PropTypes.func.isRequired,
    errorsForm: PropTypes.object.isRequired,
    setErrorsForm: PropTypes.func.isRequired,
    validateFormForm: PropTypes.func.isRequired,
    documentTypesToSelect: PropTypes.array.isRequired,
    roadTypesToSelect: PropTypes.array.isRequired,
    communityToSelect: PropTypes.array.isRequired,
    countryToSelect: PropTypes.array.isRequired,
    optTypeToSelect: PropTypes.array.isRequired
  };
  
  export default FormCreateOrEditOptTras;