import React, { useEffect, useCallback, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next" 
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import { SwipeableDrawer } from '@mui/material';
import { Container, Box } from '@mui/material';
import { TextField } from '@mui/material';
import { Checkbox} from  '@mui/material';
import { Button } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import "css/generalSettings.css";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import TableSortingVehicles from "pages/vehicles/SortingTablesVehicles";  
import PropTypes from 'prop-types'; 

const initialFilters= {
  "idFrom":"",
  "idTo":"",
  "licenseplateFrom":"",
  "licenseplateTo":"",
  "taraFrom":"",
  "taraTo":"",
  "pmaFrom":"",
  "pmaTo":"",
  "checkpma":true,
  "notcheckpma":true,
  "defaultdriverFrom":"",
	"defaultdriverTo":"",
  "vehicleStoreFrom":"",
  "vehicleStoreTo":"",
  "enabled":true,
  "notenabled":true 
}; 

const initialState = [{
  "id" : "",
  "licenseplate" : "",
  "defaultvehicle" : false,
  "tara" : 0,
  "pma" : 0,
  "checkpma" : true,
  "defaultdriver" : "",
  "enabled" : true,
  "vehiclestore_id" : "",
  "vehiclestore_tradename" : "",
 }];

 const initialStoreToSelect = [{
  "id":0,
  "documentid":"",
  "tradename":""
}];

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Text_Code',
    canOrder:true,
  },
  {
    id: 'licenseplate',
    numeric: false,
    disablePadding: false,
    label: 'Text_Vehicle_LICENSEPLATE',
    canOrder:true,
  },
  {
    id: 'tara',
    numeric: false,
    disablePadding: false,
    label: 'Text_Vehicle_TARA',
    canOrder:true,
  },
  {
    id: 'pma',
    numeric: false,
    disablePadding: false,
    label: 'Text_Vehicle_PMA',
    canOrder:true,
  },
  {
    id: 'checkpma',
    numeric: false,
    disablePadding: false,
    label: 'Text_Vehicle_CHECK',
    canOrder:true,
  },
  {
    id: 'defaultdriver',
    numeric: false,
    disablePadding: false,
    label: 'Text_Vehicle_DRIVER',
    canOrder:true,
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'Text_Vehicle_ENABLED',
    canOrder:true,
  },
  {
    id: 'vehiclestore_tradename',
    numeric: false,
    disablePadding: false,
    label: 'Text_Vehicle_STORE',
    canOrder:true,
  }
  
];
 
const VehiclesSettings = ({setShowPrincipalMenu, showVehiclesSettings, setShowVehiclesSettings,setShowModalGeneralSettings,showModalTicketsSettings,setShowModalTicketsSettings}) => {
  
  const {t} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const ENDPOINT = useSelector((state) => state.variablesUser.ENDPOINT); 
  const LIMITPAGESIZE = useSelector((state) => state.variablesUser.LIMITPAGESIZE); 
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DEFAULTSTORE = useSelector((state) => state.variablesUser.DEFAULTSTORE); 
  const [data, setData] = useState(initialState);
  const [form, setForm] = useState(initialState[0]);
  const [filters, setFilters] = useState(initialFilters);
  const [applyFilters, setApplyFilters] = useState(initialFilters);
  const [numTotReg, setNumTotReg] = useState(0);
  const [numTotRegFilter, setNumTotRegFilter] = useState(0);
  const [errorsFilters, setErrorsFilters] = useState({});
  const [errors, setErrors] = useState({});
  const [inputText, setInputText] = useState("");
  const [openCreateData, setOpenCreateData] = useState(false);
  const [openDeleteRow, setOpenDeleteRow] = useState(false);
  const [openEditRow, setOpenEditRow] = useState(false);
  const [openEditFilter, setOpenEditFilter] = useState(false);
  const [rowEdit, setRowEdit] = useState(initialState[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [idCodeDelete, setIdCodeDelete] = useState(DELETE_CODE);
  const dispatch = useDispatch();
  const [activeOffset, setActiveOffset] = useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(0);
  const [rangeOffset, setRangeOffset] = useState([{}]); 
  const [storesToSelect,setStoresToSelect]= useState(initialStoreToSelect); 
  
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showVehiclesSettings) {
        setData(initialState);
        setForm(initialState[0]);
        setFilters(initialFilters);
        setApplyFilters(initialFilters);
        setNumTotReg(0);
        setNumTotRegFilter(0);
        setErrorsFilters({});
        setErrors({});
        setInputText("");
        setOpenCreateData(false);
        setOpenDeleteRow(false);
        setOpenEditRow(false);
        setOpenEditFilter(false);
        setRowEdit(initialState[0]);
        setIsLoading(false);
        setIsLoadingError(false);
        setIdCodeDelete(DELETE_CODE);
        setActiveOffset(false);
        setCurrencyOffset(0);
        setRangeOffset([{}]);  
        setShowPrincipalMenu(true);
        setShowVehiclesSettings(false); 
        setShowModalTicketsSettings(showModalTicketsSettings);
        setStoresToSelect(initialStoreToSelect);
        setShowModalGeneralSettings(false);
      }
    },
    [ setData,
      setForm,
      setFilters,
      setApplyFilters,
      setNumTotReg,
      setNumTotRegFilter,
      setErrorsFilters,
      setErrors,
      setInputText,
      setOpenCreateData,
      setOpenDeleteRow,
      setOpenEditRow,
      setOpenEditFilter,
      setRowEdit,
      setIsLoading,
      setIsLoadingError,
      setIdCodeDelete,
      setActiveOffset,
      setCurrencyOffset,
      setRangeOffset, 
      setStoresToSelect,
      showModalTicketsSettings,
      setShowModalTicketsSettings,
      showVehiclesSettings, 
      setShowPrincipalMenu,
      setShowVehiclesSettings,
      setShowModalGeneralSettings,
      DELETE_CODE]
  );

  const closeModalUserTypeSettings = () => {    
    setData(initialState);
    setForm(initialState[0]);
    setFilters(initialFilters);
    setApplyFilters(initialFilters);
    setNumTotReg(0);
    setNumTotRegFilter(0);
    setErrorsFilters({});
    setErrors({});
    setInputText("");
    setOpenCreateData(false);
    setOpenDeleteRow(false);
    setOpenEditRow(false);
    setOpenEditFilter(false);
    setRowEdit(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    setIdCodeDelete(DELETE_CODE);
    setActiveOffset(false);
    setCurrencyOffset(0);
    setRangeOffset([{}]);  
    setStoresToSelect(initialStoreToSelect);
    setShowModalTicketsSettings(showModalTicketsSettings);
    setShowPrincipalMenu(true);
    setShowVehiclesSettings(false); 
    setShowModalGeneralSettings(false);
  };

  const openCreateOrEditData = () => {
    setInputText("");
    setOpenCreateData(true);
  };

  const openEditFilters = () => {
    setFilters(applyFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleSubmitFilterEdit = () => {
    setErrorsFilters(validateFormFilter());
    if (Object.keys(errorsFilters).length === 0) {
      setApplyFilters(filters);
      setFilters(initialFilters);
      setErrorsFilters({});
      setOpenEditFilter(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  
  const handleDeleteFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setApplyFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };

  const handleCancelFilters = () => {
    setErrorsFilters({});
    setFilters(initialFilters);
    setOpenEditFilter(!openEditFilter);
  };
  
  const handleChangeFilterEdit = (e) => {
    const { name, value } = e.target;
    setFilters(filters => ({
      ...filters,
      [name]: value
    }));
  };

  const handleBlurFilterEdit = (e) => {
    setErrorsFilters(validateFormFilter());
    handleChangeFilterEdit(e);
  };

  const handleChangeEnabledFilter = (e) => {
    setFilters(filters => ({
      ...filters,
      enabled: !filters.enabled
    }));
  };

  const handleChangeNotEnabledFilter = (e) => {
    setFilters(filters => ({
      ...filters,
      notenabled: !filters.notenabled
    }));
  };

  const handleChangeCheckPMAFilter = (e) => {
    setFilters(filters => ({
      ...filters,
      checkpma: !filters.checkpma
    }));
  };

  const handleChangeNOTCheckPMAFilter = (e) => {
    setFilters(filters => ({
      ...filters,
      notcheckpma: !filters.notcheckpma
    }));
  };

  const toggleDrawerFilterEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      setErrorsFilters({});
      setFilters(initialFilters);
      setOpenEditFilter(!openEditFilter);
    }
  };

  const validateFormFilter = () => {
    let errorsFilters = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filters.idFrom!=="" & !regexNumber.test(filters.idFrom)) {
      errorsFilters.idFrom = 'Text_TextNoNumber';
    }
    if (filters.idTo!=="" & !regexNumber.test(filters.idTo)) {
      errorsFilters.idTo = 'Text_TextNoNumber';
    }
    if (filters.idFrom!=="" & !errorsFilters.idFrom!==undefined){
      if (filters.idTo!=="" & !errorsFilters.idTo!==undefined){
        if (parseInt(filters.idTo)<parseInt(filters.idFrom)){
          errorsFilters.idTo = 'Text_TextGreater_Id';
        }
      }
    }
    if (filters.taraFrom!=="" & !regexNumber.test(filters.taraFrom)) {
      errorsFilters.taraFrom = 'Text_TextNoNumber';
    }
    if (filters.taraTo!=="" & !regexNumber.test(filters.taraTo)) {
      errorsFilters.taraTo = 'Text_TextNoNumber';
    }
    if (filters.taraFrom!=="" & !errorsFilters.taraFrom!==undefined){
      if (filters.taraTo!=="" & !errorsFilters.taraTo!==undefined){
        if (parseInt(filters.taraTo)<parseInt(filters.taraFrom)){
          errorsFilters.taraTo = 'Text_TextGreater_Tara';
        }
      }
    }

    if (filters.pmaFrom!=="" & !regexNumber.test(filters.pmaFrom)) {
      errorsFilters.pmaFrom = 'Text_TextNoNumber';
    }
    if (filters.pmaTo!=="" & !regexNumber.test(filters.pmaTo)) {
      errorsFilters.pmaTo = 'Text_TextNoNumber';
    }
    if (filters.pmaFrom!=="" & !errorsFilters.pmaFrom!==undefined){
      if (filters.pmaTo!=="" & !errorsFilters.pmaTo!==undefined){
        if (parseInt(filters.pmaTo)<parseInt(filters.pmaFrom)){
          errorsFilters.pmaTo = 'Text_TextGreater_Pma';
        }
      }
    }

    if (regexInvalidCharacters.test(filters.licenseplateFrom)) {
      errorsFilters.licenseplateFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.licenseplateTo)) {
      errorsFilters.licenseplateTo = 'Text_TextNoValid';
    } 

    if (filters.licenseplateTo!=="" & filters.licenseplateTo<filters.licenseplateFrom){
      errorsFilters.licenseplateTo = 'Text_TextGreater_LicensePlate';
    }

    if (regexInvalidCharacters.test(filters.defaultdriverFrom)) {
      errorsFilters.defaultdriverFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filters.defaultdriverTo)) {
      errorsFilters.defaultdriverTo = 'Text_TextNoValid';
    } 

    if (filters.defaultdriverTo!=="" & filters.defaultdriverTo<filters.defaultdriverFrom){
      errorsFilters.defaultdriverTo = 'Text_TextGreater_DefaultDriver';
    }
    return errorsFilters;
  }  

  const filteredData = data.filter((el) => {
    if (inputText === '') {
        return el;
    }
    else {
      let dataTranslate=t(el.id+el.licenseplate+el.defaultdriver+el.vehiclestore_tradename) ;
      return dataTranslate.toLowerCase().includes(inputText);
    }
  })

  let inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const handleChangeDeleteRow = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRow = () => {
    setErrors({});
    setRowEdit(initialState[0]);
    setForm(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);

  };

  const handleCloseDeleteRow = (e) => {
    if (idCodeDelete !== DELETE_CODE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    if (e.key !== 'Escape') {
      if (rowEdit.id !== '') {
        const fetchData = async () => {
          setIsLoading(true);
          try {
            const getData = await helpHttp().del(ENDPOINT, `vehicles/deletevehicles`, token, rowEdit);
            if (getData.status === "OK") {
              dispatch(successErrorAction(t('Text_ValidCode')));
              dispatch(successErrorAction(t('Text_DeleteField')));
            } else {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData')));
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    } 
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRow(false);
    handleCloseDataEdit();
  };

  const handleSubmitDataEdit = () => {
    setErrors(validateForm());
    const errorsToCheck = validateForm();
  
    if (Object.keys(errorsToCheck).length === 0 && form.licenseplate !== '' ) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const getData = openEditRow
            ? await helpHttp().put(ENDPOINT, `vehicles/updatevehicles`, token, form)
            : await helpHttp().post(ENDPOINT, `vehicles/savevehicles`, token, form);
  
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            handleCloseDataEdit();
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    } else {
      if (errorsToCheck.licenseplate) {
        dispatch(warningErrorAction(t(errorsToCheck.licenseplate)));
      }else{
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    }
  };

  const handleCloseDataEdit = () => {
    setOpenCreateData(false);
    setOpenEditRow(false);
    setErrors({});
    setRowEdit(openEditRow?rowEdit:initialState[0]);
    setForm(initialState[0]);
    setIsLoading(false);
    setIsLoadingError(false);
    toggleDrawerDataEdit(false);
	};

  const handleBlurDataEdit = (e) => {
    handleChangeDataEdit(e);
    setErrors(validateForm());
  };
  
  const handleChangeDataEdit = (e) => {
    const { name, value } = e.target;
    setForm(form => ({
      ...form,
      [name]: value
    }));
  };

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 10000000) return true;
    return false;
  };

  const handleBlurNumeric = (value, row) => {
    handleChangeNumeric(value, row);
  };

  const handleChangeNumeric = (e) => {
    const { name, value } = e.target;
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll(" kg","").replaceAll(" €","").replaceAll(" %","")):0;
    setForm(form => ({
      ...form,
      [name]: newValue
    }));
  };

  const handleChangeCheckPmaVehicle = () => {
    setForm(form => ({
      ...form,
      checkpma: !form.checkpma
    }));
  };

  const handleChangeEnabledVehicle = () => {
    setForm(form => ({
      ...form,
      enabled: !form.enabled
    }));
  };

  const toggleDrawerDataEdit = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault()
      handleCloseDataEdit();
    }
  };

  const validateForm = () => {
    let errors = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    if (!form.vehiclestore_id || form.vehiclestore_id==="" || form.vehiclestore_id==="0" || form.vehiclestore_id===0) {
      errors.vehiclestore_id = 'Text_FieldStoreRequired';
    } 
    if (!form.licenseplate || form.licenseplate==="") {
      errors.licenseplate = 'Text_FieldLicensePlateRequired';
    } else {
      if (regexInvalidCharacters.test(form.licenseplate)) {
        errors.licenseplate = 'Text_TextNoValid';
      } else{
        if (openEditRow){
          if (data.find((key=>(key.licenseplate.toLowerCase()===form.licenseplate.toLowerCase() && key.id!==rowEdit.id)))) {
            errors.licenseplate = 'Text_Vehicle_LicensePlateExist';
          }
        } else {
          if (data.find((key=>key.licenseplate.toLowerCase()===form.licenseplate.toLowerCase()))) {
            errors.licenseplate = 'Text_Vehicle_LicensePlateExist';
          }
        }  
      }   
    }
    return errors;
  };

  useEffect(() => {
    setForm(openEditRow?rowEdit:initialState[0]);
  },[rowEdit,openEditRow]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    if (numTotRegFilter > LIMITPAGESIZE) {
      setActiveOffset(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegFilter; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffset(newRangeOffset);
    } else {
      setCurrencyOffset(0);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  }, [numTotRegFilter, LIMITPAGESIZE]);

  useEffect(() => {
    let ignore = false; 
    const fetchDataStore = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `vehicles/storesorderbyname`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (!ignore) {
          setStoresToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  
    if ((openCreateData || openEditRow || openEditFilter)){
      fetchDataStore(); 
      return () => { ignore = true };
    }
  }, [ENDPOINT,t,token,dispatch,roles,openCreateData,openEditRow,openEditFilter]);
 

  useEffect(() => {
    let ignore = false;
  
    const fetchnumTotReg = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().get(ENDPOINT, `vehicles/getinformationtablevehicles`, token);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotReg(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    const fetchnumTotRegFilter = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().post(ENDPOINT, `vehicles/getcountfiltervehicles`, token, applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const getData = await helpHttp().get3(ENDPOINT, `vehicles/getvehicles`, token, currencyOffset, LIMITPAGESIZE, applyFilters);
        if (getData.err || getData.message === "Load failed") {
          setData(initialState);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setData(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    if (showVehiclesSettings) {
      fetchnumTotReg();
      fetchnumTotRegFilter();
      fetchData();
      return () => { ignore = true; };
    }
  }, [ENDPOINT, t, token, dispatch, openCreateData, openDeleteRow, openEditRow, rowEdit, showVehiclesSettings, LIMITPAGESIZE, applyFilters, currencyOffset]);
  
  return (
    <>
      {showVehiclesSettings && ( 
        <div className="container-fluid">
            {/* Search input */}
            <div id="myDIV">
              <h1 className="h1NewStyle mb-2">
                
              {isLoadingError?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoading?<CircularProgress color={openDeleteRow?"warning":"primary"}/>:<i className="fas fa-solid fa-truck text-warning"></i>} {t('Text_Vehicle_Config')}</h1>
                {isLoading ?
                  <CircularProgress sx={{ color : "#f6c23e"}}/>:
                  <>
                    {roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Add_Vehicle'))?

                      <div>
                        <button type="button" className="buttonCreate" onClick={openCreateOrEditData}>{t('Button_Create')}</button><> </>
                        <button type="button" className="buttonBack" onClick={closeModalUserTypeSettings}>{t('Button_Back')}</button>
                      </div>:
                      <div>
                        <button type="button" className="buttonBack" onClick={closeModalUserTypeSettings}>{t('Button_Back')}</button>
                      </div>
                    }
                  </>
              }
          </div>  
          <br/>
         
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputText}
              placeholder={t('Text_Vehicle_Search')}
              onChange={inputHandler}
              fullWidth
              label={t('Text_Vehicle_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyle mb-2">
            {t('Text_Filter')}&nbsp;
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.idFrom!=="" || applyFilters.idTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Code')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.licenseplateFrom!=="" || applyFilters.licenseplateTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Vehicle_LICENSEPLATE')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.taraFrom!=="" || applyFilters.taraTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Vehicle_TARA')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.pmaFrom!=="" || applyFilters.pmaTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Vehicle_PMA')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.defaultdriverFrom!=="" || applyFilters.defaultdriverTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Vehicle_DRIVER')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.vehicleStoreFrom!=="" || applyFilters.vehicleStoreTo!=="")?"#32CD32":"#FFFFFF"}}></i> {t('Text_Vehicle_STORE')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.checkpma)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Vehicle_CHECK')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.notcheckpma)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Vehicle_NOTCHECK')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.enabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Vehicle_ENABLED')}</button><> </>
                <button type="button" className="buttonFilter" onClick={openEditFilters}><i className="fas fa-solid fa-circle" style={{color:(applyFilters.notenabled)?"#32CD32":"#FFFFFF"}}></i> {t('Text_Vehicle_NOTENABLED')}</button><> </>
                
            </h1>
          </div>
          <br/>
          <div className="card shadow mb-4">    
            <div className="card-body">
              <TableSortingVehicles
                data={filteredData}
                headCells={headCells}
                numTotRegFilter={numTotRegFilter}
                titleTable={t('Text_Vehicle_Table')}
                setOpenDeleteRow={setOpenDeleteRow}
                setOpenEditRow={setOpenEditRow}
                setRowEdit={setRowEdit}
                showEditButton={roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Modify_Vehicle'))?true:false}
                showDeleteButton={roles[0].listmenus.find((key)=>(key.name==='Handle_Store_Delete_Vehicle'))?true:false}
                numTotReg={numTotReg}
                activeOffset={activeOffset}
                rangeOffset={rangeOffset}
                currencyOffset={currencyOffset}
                setCurrencyOffset={setCurrencyOffset}  
               >
              </TableSortingVehicles>
            </div>             
          </div>
          {openEditFilter &&
            <div>
              <SwipeableDrawer
                anchor='right'
                open={true}
                onClose={toggleDrawerFilterEdit(false)}
                onOpen={toggleDrawerFilterEdit(true)}>
                <Container >
                  <Box sx={{ bgcolor: '#ffffff', width: '1100px' }}>
                    <br/>
                    <div id="myDIV">
                      <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                    </div>
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <form className="createfiltervehicles" onSubmit={handleCancelFilters}>  
                      <div className="form-floating mb-3 px-4 primary">
                        <div className="form-group">
                          <div className="row p-2">
                            <div className="col-2">
                              <label className="text-primary">{t('Text_Filter_Code_From')} :</label>
                            </div>
                            <div className="col-4">
                              <TextField
                                id="idFrom" 
                                name="idFrom"
                                value={filters.idFrom}
                                inputProps={{ style: {width: 300}, maxLength: 15 }}
                                label={errorsFilters.idFrom!=null?t(errorsFilters.idFrom):t('PlaceHolder_Filter_Code_From')}
                                placeholder={t('PlaceHolder_Filter_Code_From')}
                                variant="standard"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                              />
                            </div>
                            <div className="col-2">
                              <label className="text-primary">{t('Text_Filter_Code_To')} :</label>
                            </div>
                            <div className="col-4">
                              <TextField
                                id="idTo" 
                                name="idTo"
                                value={filters.idTo}
                                inputProps={{ style: {width: 300}, maxLength: 15 }}
                                label={errorsFilters.idTo!=null?t(errorsFilters.idTo):t('PlaceHolder_Filter_Code_to')}
                                placeholder={t('PlaceHolder_Filter_Code_to')}
                                variant="standard"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row p-2">
                            <div className="col-2">
                              <label className="text-primary">{t('Text_Filter_LicensePlate_From')} :</label> 
                            </div>
                            <div className="col-4">
                              <TextField
                                id="licenseplateFrom" 
                                name="licenseplateFrom"
                                value={filters.licenseplateFrom}
                                inputProps={{ style: {width: 300}, maxLength: 50 }}
                                label={errorsFilters.licenseplateFrom!=null?t(errorsFilters.licenseplateFrom):t('Placeholder_Filter_LicensePlate_From')}
                                placeholder={t('Placeholder_Filter_LicensePlate_From')}
                                variant="standard"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                              />
                            </div>
                          
                            <div className="col-2"> 
                              <label className="text-primary">{t('Text_Filter_LicensePlate_To')} :</label>
                            </div>
                            <div className="col-4">
                              <TextField
                                id="licenseplateTo" 
                                name="licenseplateTo"
                                value={filters.licenseplateTo}
                                inputProps={{ style: {width: 300}, maxLength: 50 }}
                                label={errorsFilters.licenseplateTo!=null?t(errorsFilters.licenseplateTo):t('Placeholder_Filter_LicensePlate_To')}
                                placeholder={t('Placeholder_Filter_LicensePlate_To')}
                                variant="standard"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                              />
                            </div>
                          </div> 
                        </div>

                        <div className="form-group">
                          <div className="row p-2">
                            <div className="col-2">
                              <label className="text-primary">{t('Text_Filter_Tara_From')} :</label>
                            </div>
                            <div className="col-4">
                              <TextField
                                id="taraFrom" 
                                name="taraFrom"
                                value={filters.taraFrom}
                                inputProps={{ style: {width: 300}, maxLength: 15 }}
                                label={errorsFilters.taraFrom!=null?t(errorsFilters.taraFrom):t('Placeholder_Filter_Tara_From')}
                                placeholder={t('Placeholder_Filter_Tara_From')}
                                variant="standard"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                              />
                            </div>
                            <div className="col-2"> 
                              <label className="text-primary">{t('Text_Filter_Tara_To')} :</label>
                            </div>
                            <div className="col-4">
                              <TextField
                                id="taraTo" 
                                name="taraTo"
                                value={filters.taraTo}
                                inputProps={{ style: {width: 300}, maxLength: 15 }}
                                label={errorsFilters.taraTo!=null?t(errorsFilters.taraTo):t('Placeholder_Filter_Tara_To')}
                                placeholder={t('Placeholder_Filter_Tara_To')}
                                variant="standard"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                              />
                            </div>
                          </div> 
                        </div>

                        <div className="form-group">
                          <div className="row p-2">
                            <div className="col-2">
                              <label className="text-primary">{t('Text_Filter_Pma_From')} :</label>
                            </div>
                            <div className="col-4">
                              <TextField
                                id="pmaFrom" 
                                name="pmaFrom"
                                value={filters.pmaFrom}
                                inputProps={{ style: {width: 300}, maxLength: 15 }}
                                label={errorsFilters.pmaFrom!=null?t(errorsFilters.pmaFrom):t('Placeholder_Filter_Pma_From')}
                                placeholder={t('Placeholder_Filter_Pma_From')}
                                variant="standard"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                              />
                            </div>
                            <div className="col-2"> 
                              <label className="text-primary">{t('Text_Filter_Pma_To')} :</label>
                            </div>
                            <div className="col-4">
                              <TextField
                                id="pmaTo" 
                                name="pmaTo"
                                value={filters.pmaTo}
                                inputProps={{ style: {width: 300}, maxLength: 15 }}
                                label={errorsFilters.pmaTo!=null?t(errorsFilters.pmaTo):t('Placeholder_Filter_Pma_To')}
                                placeholder={t('Placeholder_Filter_Pma_To')}
                                variant="standard"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                                />
                            </div>
                          </div> 
                        </div>

                        <div className="form-group">
                          <div className="row p-2">
                            <div className="col-2">
                              <label className="text-primary">{t('Text_Vehicle_CHECK')}</label>
                            </div>
                            <div className="col-4">
                              <Checkbox
                                id="checkpma" 
                                name="checkpma"
                                checked={filters.checkpma}
                                onChange={handleChangeCheckPMAFilter}
                              variant="standard"/>
                            </div>
                            <div className="col-2"> 
                              <label className="text-primary">{t('Text_Vehicle_NOTCHECK')} :</label>
                            </div>
                            <div className="col-4">
                              <Checkbox
                                id="notcheckpma" 
                                name="notcheckpma"
                                checked={filters.notcheckpma}
                                onChange={handleChangeNOTCheckPMAFilter}
                              variant="standard"/>
                            </div>
                          </div> 
                        </div>

                        <div className="form-group">
                          <div className="row p-2">
                            <div className="col-2">
                              <label className="text-primary">{t('Text_Filter_Driver_From')} :</label>
                            </div>
                            <div className="col-4">
                              <TextField
                                id="defaultdriverFrom" 
                                name="defaultdriverFrom"
                                value={filters.defaultdriverFrom}
                                inputProps={{ style: {width: 300}, maxLength: 100 }}
                                label={errorsFilters.defaultdriverFrom!=null?t(errorsFilters.defaultdriverFrom):t('Placeholder_Filter_Driver_From')}
                                placeholder={t('Placeholder_Filter_Driver_From')}
                                variant="standard"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                              />
                            </div>
                            <div className="col-2"> 
                              <label className="text-primary">{t('Text_Filter_Driver_To')} :</label>
                            </div>
                            <div className="col-4">
                              <TextField
                                id="defaultdriverTo" 
                                name="defaultdriverTo"
                                value={filters.defaultdriverTo}
                                inputProps={{ style: {width: 300}, maxLength: 100 }}
                                label={errorsFilters.defaultdriverTo!=null?t(errorsFilters.defaultdriverTo):t('Placeholder_Filter_Driver_To')}
                                placeholder={t('Placeholder_Filter_Driver_To')}
                                variant="standard"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                              />
                            </div>
                          </div> 
                        </div>

                        <div className="form-group">
                          <div className="row p-2">
                            <div className="col-2">
                              <label className="text-primary">{t('Text_Filter_Store_From')} :</label>
                            </div>
                            <div className="col-4">
                              <TextField
                                id="vehicleStoreFrom"
                                name="vehicleStoreFrom"
                                value={ 
                                  (filters.vehicleStoreFrom!== undefined ||
                                    filters.vehicleStoreFrom!== null ||
                                    filters.vehicleStoreFrom!== "" ||
                                    filters.vehicleStoreFrom!== "0" ||
                                    filters.vehicleStoreFrom!== 0 ) ? filters.vehicleStoreFrom:0}
                                inputProps={{ style: {width: 260}, maxLength: 100 }}
                                variant="outlined"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                                helperText={errors.vehicleStoreFrom!=null?t(errors.vehicleStoreFrom):""}
                                select
                                SelectProps={{
                                    native: true,
                                    value: 
                                      (filters.vehicleStoreFrom!== undefined ||
                                        filters.vehicleStoreFrom!== null ||
                                        filters.vehicleStoreFrom!== "" ||
                                        filters.vehicleStoreFrom!== "0" ||
                                        filters.vehicleStoreFrom!== 0 ) ? filters.vehicleStoreFrom:0
                                    }}>
                                    {storesToSelect.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.documentid+" - "+option.tradename}
                                    </option>
                                    ))}
                              </TextField>
                            </div>
                            <div className="col-2"> 
                              <label className="text-primary">{t('Text_Filter_Store_To')} :</label>
                            </div>
                            <div className="col-4">
                              <TextField
                                id="vehicleStoreTo"
                                name="vehicleStoreTo"
                                value={filters.vehicleStoreTo}  
                                inputProps={{ style: {width: 260}, maxLength: 100 }}
                                label={errorsFilters.vehicleStoreTo!=null?t(errorsFilters.vehicleStoreTo):t('Text_Filter_Store_To')}
                                placeholder={t('PlaceHolder_Filter_Store_to')}
                                variant="outlined"
                                onBlur={handleBlurFilterEdit}
                                onChange={handleChangeFilterEdit}
                                select
                                SelectProps={{
                                native: true,
                                }}>
                                <option key={0} value=""/>
                                {storesToSelect.filter(item=>(item.id>=filters.vehicleStoreFrom)).map(option => (
                                  <option key={option.id} value={option.id}>
                                      {option.documentid+" - "+option.tradename}
                                  </option>
                                ))}
                              </TextField> 
                            </div>
                          </div> 
                        </div> 

                        <div className="form-group">
                          <div className="row p-2">
                            <div className="col-2">
                              <label className="text-primary">{t('Text_Vehicle_ENABLED')}</label>
                            </div>
                            <div className="col-4">
                              <Checkbox
                                id="enabled" 
                                name="enabled"
                                checked={filters.enabled}
                                onChange={handleChangeEnabledFilter}
                              variant="standard"/>
                            </div>
                            <div className="col-2"> 
                              <label className="text-primary">{t('Text_Vehicle_NOTENABLED')} :</label>
                            </div>
                            <div className="col-4">
                              <Checkbox
                                id="notenabled" 
                                name="notenabled"
                                checked={filters.notenabled}
                                onChange={handleChangeNotEnabledFilter}
                              variant="standard"/>
                            </div>
                          </div> 
                        </div> 


                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitFilterEdit}>{t('Button_Apply')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleDeleteFilters}>{t('Button_Cancel_Filters')}</button>
                        </div>
                      </div>
                    </form>
                  </Box>
                </Container>
              </SwipeableDrawer>
            </div>
          }
          {(openCreateData || openEditRow) && 
            <div>
              <SwipeableDrawer
                anchor='right'
                open={true}
                onClose={toggleDrawerDataEdit(false)}
                onOpen={toggleDrawerDataEdit(true)}>
                <Container >
                  <Box sx={{ bgcolor: '#ffffff',width: '500px' }}>
                    <br/>
                    <div id="myDIV">
                      <h1 className="h1NewStyle px-4 mb-2">{openEditRow?t('Text_Vehicle_Edit'):t('Text_Vehicle_Create')}</h1>
                    </div>  
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <form className="createVehicles" onSubmit={handleSubmitDataEdit}>  
                      <div className="form-floating mb-3 px-4 primary">
                        <label className="text-primary">{t('Text_Code')} :</label>
                        <div className="form-group">
                          <TextField
                            id="id" 
                            name="id"
                            value={form.id}
                            inputProps={{ style: {width: 250} }}
                            label={t('PlaceHolder_Vehicles_Code')}
                            placeholder={t('PlaceHolder_Vehicles_Code')}
                            variant="standard"
                            disabled/>
                        </div>
                        <label className="text-primary">{t('Text_Vehicle_licenseplate')} :</label>
                        <div className="form-group">
                          <TextField
                            id="licenseplate" 
                            name="licenseplate"
                            value={form.licenseplate}
                            inputProps={{ style: {width: 450}, maxLength: 50  }}
                            label={errors.licenseplate!=null?t(errors.licenseplate):t('PlaceHolder_Vehicle_licenseplate')}
                            placeholder={t('PlaceHolder_Vehicle_licenseplate')}
                            variant="standard"
                            onBlur={handleBlurDataEdit}
                            onChange={handleChangeDataEdit}
                          />
                        </div>
                        <label className="text-primary">{t('Text_Vehicle_tara')} :</label>
                        <div className="form-group">
                          <NumericFormat 
                            thousandSeparator={','} 
                            decimalSeparator={'.'} 
                            decimalScale= {2}
                            fixedDecimalScale= {true}
                            allowNegative= {false}
                            isAllowed={withValueCap}
                            id="tara" 
                            name="tara"
                            suffix={" kg"}
                            value={form.tara}
                            label={errors.tara!=null?t(errors.tara):t('PlaceHolder_Vehicle_tara')}
                            placeholder={t('PlaceHolder_Vehicle_tara')}
                            style={{width: 450}}
                            customInput={TextField}
                            onBlur={handleBlurNumeric}
                            onChange={handleChangeNumeric}
                          />
                        </div> 
                        <label className="text-primary">{t('Text_Vehicle_pma')} :</label>
                        <div className="form-group">
                          <NumericFormat 
                            thousandSeparator={','} 
                            decimalSeparator={'.'} 
                            decimalScale= {2}
                            fixedDecimalScale= {true}
                            allowNegative= {false}
                            isAllowed={withValueCap}
                            id="pma" 
                            name="pma"
                            suffix={" kg"}
                            value={form.pma}
                            label={errors.pma!=null?t(errors.pma):t('PlaceHolder_Vehicle_pma')}
                            placeholder={t('PlaceHolder_Vehicle_pma')}
                            style={{width: 450}}
                            customInput={TextField}
                            onBlur={handleBlurNumeric}
                            onChange={handleChangeNumeric}
                          /> 
                        </div>
                        <div className="form-group">
                          <label className="text-primary">{t('Text_Vehicle_checkpma')} :</label>
                          <Checkbox  
                            style={{color:"#4e73df"}} 
                            checked={form.checkpma} 
                            onChange={handleChangeCheckPmaVehicle} 
                          />
                        </div>

                        <label className="text-primary">{t('Text_Vehicle_driver')} :</label>
                        <div className="form-group">
                          <TextField
                            id="defaultdriver" 
                            name="defaultdriver"
                            value={form.defaultdriver}
                            inputProps={{ style: {width: 450}, maxLength: 100  }}
                            label={errors.defaultdriver!=null?t(errors.defaultdriver):t('PlaceHolder_Vehicle_driver')}
                            placeholder={t('PlaceHolder_Vehicle_driver')}
                            variant="standard"
                            onBlur={handleBlurDataEdit}
                            onChange={handleChangeDataEdit}
                          />
                        </div>                      
                        <div className="form-group">
                          <label className="text-primary">{t('Text_Vehicle_enabled')} :</label>
                          <Checkbox  
                            style={{color:"#4e73df"}} 
                            checked={form.enabled} 
                            onChange={handleChangeEnabledVehicle} 
                          />
                        </div>
                        <label className="text-primary">{t('Text_User_Stores')} :</label>
                        <div className="form-group">
                          <TextField
                            id="vehiclestore_id"
                            name="vehiclestore_id"
                            value={ 
                              (form.vehiclestore_id!== undefined ||
                              form.vehiclestore_id!== null ||
                              form.vehiclestore_id!== "" ||
                              form.vehiclestore_id!== "0" ||
                              form.vehiclestore_id!== 0 ) ? form.vehiclestore_id:Number(DEFAULTSTORE)}
                            inputProps={{ style: {width: 405}, maxLength: 100 }}
                            variant="outlined"
                            onBlur={handleBlurDataEdit}
                            onChange={handleChangeDataEdit}
                            helperText={errors.store_id!=null?t(errors.store_id):""}
                            select
                            SelectProps={{
                                native: true,
                                value: 
                                  (form.vehiclestore_id!== undefined ||
                                    form.vehiclestore_id!== null ||
                                    form.vehiclestore_id!== "" ||
                                    form.vehiclestore_id!== "0" ||
                                    form.vehiclestore_id!== 0 ) ? form.vehiclestore_id:Number(DEFAULTSTORE)
                                }}>
                                 <option key={0} value=""/>
                                {storesToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.documentid+" - "+option.tradename}
                                </option>
                                ))}
                          </TextField>
                        </div>


                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg " type="button" onClick={handleSubmitDataEdit}>{openEditRow?t('Button_Update'):t('Button_Create')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg" type="button" onClick={handleCloseDataEdit}>{t('Button_Cancel')}</button>
                        </div>
                      </div>
                    </form>
                  </Box>
                </Container>
              </SwipeableDrawer>
            </div>
          }  
          <Dialog open={openDeleteRow} onClose={handleCancelDeleteRow}>
            <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEdit.id}  - {rowEdit.name}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('Text_Line1')}<br/>
                {t('Text_Line2')}
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="idCode"
                label={t('Text_Label')}
                type="text"
                fullWidth
                variant="standard"
                error
                defaultValue={idCodeDelete}
                onChange={handleChangeDeleteRow}/>
            </DialogContent>
            <DialogActions>
              <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRow}>{t('Button_Cancel')}</Button>
              <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRow}>{t('Button_Delete')}</Button>
            </DialogActions>
          </Dialog>
          {/* End Content Row */}
        </div>
        //* End Page Content *//
      )}
    </>
  )
}
VehiclesSettings.propTypes = {
  setShowPrincipalMenu: PropTypes.func.isRequired,
  showVehiclesSettings: PropTypes.bool.isRequired,
  setShowVehiclesSettings: PropTypes.func.isRequired,
  setShowModalGeneralSettings: PropTypes.func.isRequired ,
  showModalTicketsSettings: PropTypes.bool.isRequired,
  setShowModalTicketsSettings: PropTypes.func.isRequired
  
};

export default VehiclesSettings;